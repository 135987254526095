import { ChangeEvent } from "react";
import styled from "styled-components/macro";

import { ImageSize } from "types/types";
import { isMobile } from "utils/utils";

import { ReactComponent as CameraIcon } from "assets/icons/photo-camera-2.svg";

import {
  CaptureBtn,
  CaptureButtonContainer,
} from "pages/task-photos/styles/task-photos.styles";

const Container = styled.div<{ width: number; height: number }>`
  width: ${({ width }) => (!!width ? `${width / 10}rem` : "100%")};
  height: ${({ height }) => (!!height ? `${height / 10}rem` : "100%")};

  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 0.6rem;

  margin: 0 auto;
`;

const IconContainer = styled.div`
  flex-basis: 79%;
  display: flex;
  justify-content: center;
  align-items: center;

  .camera-icon {
    width: 70%;
    height: 70%;

    path,
    circle {
      stroke: ${({ theme }) => theme.primary_100};
    }
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;

  width: 90%;
  margin: 0 auto;

  padding-bottom: 0.5rem;
`;

type LastSlideProps = {
  size: ImageSize;
  photoUploading: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  canTakePhotos: boolean;
  labels: {
    takePhoto: string;
    selectExisting: string;
  };
};

function LastSlide({
  size,
  photoUploading,
  onChange,
  canTakePhotos,
  labels,
}: LastSlideProps) {
  const isHorizontal = size.width > size.height;

  const width = isHorizontal ? 0 : size.width;
  const height = isHorizontal ? 0 : size.height;

  return (
    <Container width={width} height={height}>
      <IconContainer>
        <CameraIcon className='camera-icon' />
      </IconContainer>

      <Actions>
        <CaptureButtonContainer>
          <CaptureBtn
            htmlFor={!photoUploading ? "select-photo" : ""}
            isLoading={photoUploading}
            isMobile={isMobile}
          >
            {labels.selectExisting}
          </CaptureBtn>
          <input
            type='file'
            id='select-photo'
            accept='image/*'
            onChange={onChange}
            style={{
              visibility: "hidden",
              width: 0,
              height: 0,
              position: "absolute",
            }}
          />
        </CaptureButtonContainer>

        {canTakePhotos && (
          <CaptureButtonContainer>
            <CaptureBtn
              htmlFor={!photoUploading ? "capture-photo" : ""}
              className='capture'
              isLoading={photoUploading}
              isMobile={isMobile}
            >
              {labels.takePhoto}
            </CaptureBtn>
            <input
              type='file'
              id='capture-photo'
              capture='environment'
              accept='image/*'
              onChange={onChange}
              style={{
                visibility: "hidden",
                width: 0,
                height: 0,
                position: "absolute",
              }}
            />
          </CaptureButtonContainer>
        )}
      </Actions>
    </Container>
  );
}

export default LastSlide;
