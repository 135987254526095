import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";
import type { Swiper as SwiperType } from "swiper";

import useRelatedFiles from "hooks/useRelatedFiles";
import { CreatedTask } from "types/tasks.types";
import { isMobile } from "utils/utils";

import { ReactComponent as ChevronIcon } from "assets/icons/navigation-chevron.svg";

import RelatedItemContent from "./components/related-item-content/RelatedItemContent";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import { StyledLabel } from "styles/generalStyles";

const Container = styled.div`
  margin-top: 1rem;
  height: 8rem;
`;

const ListWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const RelatedList = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const RelatedItem = styled.div<{ arrowsVisible?: boolean }>`
  ${({ arrowsVisible }) =>
    !arrowsVisible &&
    css`
      flex-basis: 50%;
    `}

  ${({ arrowsVisible }) =>
    arrowsVisible &&
    css`
      width: 100%;
    `}
`;

const Arrow = styled.div<{ isMobile: boolean; back?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 6rem;
  display: flex;
  justify-content: flex-end;
  background-color: #dfe3ed;
  mask-image: linear-gradient(to left, black 35%, transparent);
  z-index: ${({ theme }) => theme.level1};
  animation: arrowFadeIn 0.25s ease-out;

  @keyframes arrowFadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  ${({ back }) =>
    back &&
    css`
      left: 0;
      justify-content: flex-start;
      mask-image: linear-gradient(to right, black 35%, transparent);
    `}

  .chevron-icon {
    width: 2.5rem;
    height: 100%;
    cursor: ${({ isMobile }) => (!isMobile ? "pointer" : "none")};
  }

  .forward {
    transform: rotate(180deg);
  }
`;

type RelatedFilesProps = {
  task: CreatedTask;
  label: string;
  permittedToReadRelated: boolean;
};

const swiperPositionInit = {
  isBeginning: true,
  isMiddle: false,
  isEnd: false,
};

function RelatedFiles({ task, label, permittedToReadRelated }: RelatedFilesProps) {
  const { relatedList } = useRelatedFiles(task);

  const [swiperInstance, setSwiperInstance] = useState<SwiperType>();
  const [swiperPosition, setSwiperPosition] = useState(swiperPositionInit);

  const navigate = useNavigate();

  const relatedListLength = relatedList.length;
  const arrowsVisible = relatedList ? relatedListLength > 3 : false;
  const leftArrowVisible =
    arrowsVisible && (swiperPosition.isEnd || swiperPosition.isMiddle);
  const rightArrowVisible =
    arrowsVisible && (swiperPosition.isBeginning || swiperPosition.isMiddle);

  // --------------- On click handlers ---------------

  const onForwardArrowClick = () => {
    if (!swiperInstance) return;
    swiperInstance.slideNext();
  };

  const onBackArrowClick = () => {
    if (!swiperInstance) return;
    swiperInstance.slidePrev();
  };

  const onRelatedItemClick = (id: number) => () => {
    navigate(`/task/selected/related/${id}`, { replace: true });
  };

  // --------------- On change handlers ---------------

  const onSlideChange = (e: SwiperType) => {
    const isBeginning = e.activeIndex === 0;
    const isEnd = e.activeIndex === Math.trunc(relatedListLength / 2) + 1;
    const isMiddle = !isBeginning && !isEnd;

    setSwiperPosition({ isBeginning, isMiddle, isEnd });
  };

  if (!permittedToReadRelated) {
    return null;
  }

  return !!relatedList?.length ? (
    <Container>
      <StyledLabel>{label}</StyledLabel>

      <ListWrapper>
        {leftArrowVisible && (
          <Arrow isMobile={isMobile} back>
            <ChevronIcon className='chevron-icon' onClick={onBackArrowClick} />
          </Arrow>
        )}

        {relatedListLength <= 3 && (
          <RelatedList>
            {relatedList.map((item) => (
              <RelatedItem onClick={onRelatedItemClick(item.id)} key={item.id}>
                <RelatedItemContent relatedItem={item} />
              </RelatedItem>
            ))}
          </RelatedList>
        )}

        {relatedListLength > 3 && (
          <Swiper
            slidesPerView={2}
            spaceBetween={10}
            freeMode={true}
            modules={[FreeMode]}
            onSlideChange={onSlideChange}
            onSwiper={(swiper) => setSwiperInstance(swiper)}
          >
            {relatedList.map((item) => (
              <SwiperSlide key={item.id} onClick={onRelatedItemClick(item.id)}>
                <RelatedItem arrowsVisible>
                  <RelatedItemContent relatedItem={item} />
                </RelatedItem>
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        {rightArrowVisible && (
          <Arrow isMobile={isMobile}>
            <ChevronIcon className='chevron-icon forward' onClick={onForwardArrowClick} />
          </Arrow>
        )}
      </ListWrapper>
    </Container>
  ) : null;
}

export default RelatedFiles;
