import { useState } from "react";
import { Pagination, Navigation } from "swiper";
import type { Swiper as SwiperType } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";

import { isMobile } from "utils/utils";
import useTaskAttachments from "hooks/useTaskAttachments";
import { CreatedTask, DirectusFile } from "types/tasks.types";

import { ReactComponent as FullScreenIcon } from "assets/icons/fullscreen.svg";

import BackArrow from "components/atoms/BackArrow";
import PreviewImage from "pages/task-photos/components/preview-image/PreviewImage";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { ContentContainer } from "styles/generalStyles";
import {
  Container,
  FullScreenIconContainer,
  GalleryContainer,
  PhotoCounter,
} from "pages/task-photos/styles/task-photos.styles";
import { Header } from "pages/task-creator/styles/task-creator.styles";

type Task = { id: string; files: DirectusFile[] };

const getLocalData = () => {
  const localData = sessionStorage.getItem("selectedTask");
  const selectedTask: CreatedTask = localData ? JSON.parse(localData) : null;

  return selectedTask
    ? { id: selectedTask.id, files: selectedTask.Files }
    : { id: "", files: [] };
};

function SelectedTaskPhotos() {
  const [task] = useState<Task>(getLocalData());
  const [currentIndex, setCurrentIndex] = useState(0);
  const { photoImgSources } = useTaskAttachments(task.files);
  const navigate = useNavigate();

  const onBackArrowClick = () => {
    const path = `/task/selected?id=${task.id}`;
    navigate(path, { replace: true });
  };

  const onSlideChange = ({ activeIndex }: SwiperType) => {
    setCurrentIndex(activeIndex);
  };

  return (
    <ContentContainer>
      <Header isMobile={isMobile}>
        <BackArrow onClick={onBackArrowClick} customClass='move-arrow' />
        <span className='header-title'>Zadanie&nbsp;&#8250;&nbsp;zdjęcia</span>
      </Header>

      <Container>
        <GalleryContainer isMobile={isMobile}>
          <Swiper
            pagination={{
              type: "progressbar",
            }}
            navigation
            rewind
            modules={[Pagination, Navigation]}
            onSlideChange={onSlideChange}
            spaceBetween={10}
            initialSlide={0}
          >
            {photoImgSources.map((src) => {
              return (
                <SwiperSlide key={`${src}`}>
                  <PreviewImage src={src} />

                  <FullScreenIconContainer
                    isMobile={isMobile}
                    href={src}
                    rel='noreferrer'
                  >
                    <FullScreenIcon className='fullscreen-icon' />
                  </FullScreenIconContainer>
                </SwiperSlide>
              );
            })}

            <PhotoCounter>
              {currentIndex + 1}&nbsp;/&nbsp;
              {photoImgSources.length}
            </PhotoCounter>
          </Swiper>
        </GalleryContainer>
      </Container>
    </ContentContainer>
  );
}

export default SelectedTaskPhotos;
