import styled from "styled-components/macro";

export const DocumentsContainer = styled.div`
  flex-basis: 95%;
  overflow-y: auto;
  position: relative;
`;

export const DocumentsList = styled.ul`
  height: 100%;
`;

export const DocumentItem = styled.li<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 0.4rem;
  cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};
  transition: background-color 0.2s ease-out;
  position: relative;

  &:not(:last-of-type) {
    margin-bottom: 0.5rem;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.7);
  }
`;

export const UploadOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(33, 47, 90, 0.3);
  border-radius: 0.4rem;
`;

export const ProgressContainer = styled.div`
  width: 95%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const BinContainer = styled.div<{ isMobile: boolean }>`
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);
  /* border: 1px solid red; */

  .bin-icon {
    width: 100%;
    height: 100%;
  }
`;
