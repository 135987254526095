import { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import useTimeout from "hooks/useTimeout";
import { getTranslations } from "./translations/uploadProgressBar.translations";

const Container = styled.div`
  margin-top: 2rem;
`;

const ProgressBarContainer = styled.div<{ progress: number }>`
  margin: 0 auto;
  max-width: 40rem;
  text-align: center;
  overflow-x: hidden;
  border-radius: 1.5rem;
  position: relative;

  .track {
    padding: 0.4rem;
    background: ${({ theme }) => theme.primary};
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
  }

  .bar {
    height: 3rem;
    border-radius: 1rem;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.05)
    );
    transition: 0.4s linear;
    transition-property: width, background-color;
    background-color: ${({ theme }) => theme.azure};
    width: ${({ progress }) => `${progress}%`};
  }
`;

const ServerResponseBarContainer = styled.div`
  height: 3rem;
  width: 100%;
`;

const ServerResponseBar = styled.div`
  height: 100%;
  width: calc(113px * 4);
  box-shadow: 0px 10px 13px -6px rgba(44, 62, 80, 1);
  background-color: ${({ theme }) => theme.listItemColor};
  background-image: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 20px,
    #212f5a 20px,
    #212f5a 40px
  );

  animation: slide 1.5s linear infinite;
  will-change: background-position;

  @keyframes slide {
    from {
      background-position-x: 0;
    }
    to {
      background-position-x: 113px;
    }
  }
`;

const LabelContainer = styled.div<{ dark: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: GothamBold;
  background-color: ${({ dark }) =>
    dark ? "rgba(33, 47, 90, 0.75)" : "rgba(0, 0, 0, 0)"};
  color: ${({ theme }) => theme.textColor1};
`;

const Label = styled.div``;

function UploadProgressBar({ progress }: { progress: number }) {
  const [showServerResponseBar, setShowServerResponseBar] = useState(false);
  const setTimeOut = useTimeout();
  const {
    i18n: { language },
  } = useTranslation();
  const {
    labels: { uploading, waitingForServerResponse },
  } = getTranslations(language);

  // const label = showServerResponseBar
  //   ? waitingForServerResponse
  //   : `${uploading}: ${progress}%`;

  const label = `${uploading}: ${progress}%`;

  useEffect(() => {
    if (progress === 100) {
      setTimeOut(() => {
        setShowServerResponseBar(true);
      }, 1000);
    }
  }, [progress, setTimeOut]);

  return (
    <Container>
      <ProgressBarContainer progress={progress}>
        {!showServerResponseBar ? (
          <div className='track'>
            <div className='bar' />
          </div>
        ) : (
          <ServerResponseBarContainer>
            <ServerResponseBar />
          </ServerResponseBarContainer>
        )}

        <LabelContainer dark={showServerResponseBar}>
          <Label>{label}</Label>
        </LabelContainer>
      </ProgressBarContainer>
    </Container>
  );
}

export default UploadProgressBar;
