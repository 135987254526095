import styled, { css } from "styled-components/macro";

import { FileType } from "types/tasks.types";

import { ReactComponent as PDFIcon } from "assets/icons/file-types/pdf.svg";
import { ReactComponent as JPGIcon } from "assets/icons/file-types/jpg.svg";
import { ReactComponent as PNGIcon } from "assets/icons/file-types/png.svg";
import { ReactComponent as GenericFileIcon } from "assets/icons/file.svg";

const Container = styled.div<{ small: boolean }>`
  overflow: hidden;
  width: ${({ small }) => (small ? "2.5rem" : "4rem")};

  .file-icon {
    width: 3.5rem;
    height: 3.5rem;

    ${({ small }) =>
      small &&
      css`
        width: 2.5rem;
        height: 2.5rem;
      `}
  }
`;

type FileIconProps = {
  fileType: FileType;
  small?: boolean;
};

const { PDF, JPEG, JPG, PNG, DOC, DOCX } = FileType;

function FileIcon({ fileType, small = false }: FileIconProps) {
  let icon = <GenericFileIcon className='file-icon' />;

  switch (fileType) {
    case PDF:
      icon = <PDFIcon className='file-icon' />;
      break;
    case PNG:
      icon = <PNGIcon className='file-icon' />;
      break;
    case JPEG:
    case JPG:
      icon = <JPGIcon className='file-icon' />;
      break;
    case DOC:
    case DOCX:
  }

  return <Container small={small}>{icon}</Container>;
}

export default FileIcon;
