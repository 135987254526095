import { useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components/macro";
import { useLocation, useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

import AuthContext from "contexts/auth-context/AuthContext";
import useNetworkStatus from "hooks/useNetworkStatus";
import useServiceWorker from "hooks/useServiceWorker";
import useUpdateDefer from "hooks/useUpdateDefer";
import useModal from "hooks/useModal";
import { isMobile } from "utils/utils";
import { getTopBarTranslations } from "./translations/topBar.translations";
import { environment } from "utils/environment.utils";
import { Environments } from "types/environment.types";

import { ReactComponent as OfflineIcon } from "assets/icons/offline.svg";
import { ReactComponent as UpdateIcon } from "assets/icons/update.svg";

import SnapCheckLogo from "../../atoms/logos/SnapCheckLogo";
import UserAvatar from "./components/UserAvatar";
import UpdatePopUp from "components/molecules/update-popup/UpdatePopUp";
import Flags from "components/molecules/flags/Flags";

const Container = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.topBarHeightLow};
  padding: 0 1.5rem;
  background-color: ${({ theme }) => theme.white};
  position: fixed;
  left: 0;
  top: 0;
  z-index: ${({ theme }) => theme.level2};
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);

  display: flex;
  align-items: center;
  justify-content: space-between;

  transition: height 1s ease-in-out;

  @media screen and (min-height: 616px) {
    height: ${({ theme }) => theme.topBarHeight};
  }
`;

const OfflineAlert = styled.div`
  background-color: ${({ theme }) => theme.babyYellow};
  padding: 0.2rem 1rem;
  border-radius: 0.4rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scale(0.8);

  display: flex;
  align-items: center;

  .offline-icon {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.5rem;
  }

  animation: showAlert 0.3s ease-out forwards;

  @media screen and (min-height: 616px) {
    transform: translateX(-50%) scale(1);
  }

  @keyframes showAlert {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const UpdateInfo = styled.button<{
  isMobile: boolean;
  updating: boolean;
}>`
  position: absolute;
  top: 1rem;
  left: 5.2rem;
  background-color: transparent;
  outline: none;
  border: none;
  font-family: GothamBook;
  border-radius: 0.4rem;
  padding-top: 0.2rem;
  padding-right: 0.5rem;
  padding-bottom: 0.2rem;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  cursor: ${({ isMobile, updating }) => (!isMobile && !updating ? "pointer" : "default")};

  @media screen and (min-height: 616px) {
    top: 1.4rem;
    left: 6rem;
  }

  .update-icon {
    width: 2.2rem;
    height: 2.2rem;

    ${({ updating }) =>
      updating &&
      css`
        ${({ theme }) => theme.rotate};
      `}
  }

  .title {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.primary};
    user-select: none;
  }

  ${({ isMobile, updating }) =>
    !isMobile &&
    !updating &&
    css`
      &:hover .title {
        text-decoration: underline;
      }
    `}

  ${({ isMobile, updating }) =>
    isMobile &&
    !updating &&
    css`
      &:active {
        background-color: ${({ theme }) => theme.greyLight};
      }
    `}
`;

const StyledToast = styled(ToastContainer)`
  font-size: 2rem;
`;

const { PRODUCTION } = Environments;

function TopBar() {
  const { pathname } = useLocation();
  const { isOnline } = useNetworkStatus();
  const { authenticated } = useContext(AuthContext);
  const { setReminderInStorage } = useUpdateDefer();
  const {
    i18n: { language },
  } = useTranslation("common");
  const { alert } = getTopBarTranslations(language);

  const { open, openModal, closeModal } = useModal();

  // const [updateDiscarded, setUpdateDiscarded] = useState(false);
  const [clickCount, setClickCount] = useState(0);

  const [, setSearchParams] = useSearchParams();

  const {
    newVersionAvailable,
    setNewVersionAvailable,
    handleUpdate,
    updating,
    setUpdating,
  } = useServiceWorker();
  const title = !updating ? alert.update : alert.updating;

  const userAvatarVisible =
    pathname !== "/forgot-password" &&
    !pathname.includes("login") &&
    !pathname.includes("/reset-password") &&
    !pathname.includes("/invite") &&
    !pathname.includes("/apk-update-info") &&
    authenticated;

  const isQuestionnaire = ["/questionnaire", "/accident-form"].some((path) =>
    pathname.includes(path),
  );

  const updateInfoVisible = false;
  // const updateInfoVisible =
  //   (newVersionAvailable && updateDiscarded) || (newVersionAvailable && isQuestionnaire);

  // --------------- On click handlers ---------------

  const onUpdateButtonClick = () => {
    if (!updating) {
      setUpdating(true);
      handleUpdate();
    }
  };

  const onDeferButtonClick = () => {
    setNewVersionAvailable(false);
    setReminderInStorage();
    closeModal();
  };

  // const onCloseModalIconClick = () => {
  //   closeModal();
  //   setUpdateDiscarded(true);
  // };

  const onLogoClick = () => {
    // window.location.reload();
    if (!pathname.includes("/login")) return;
    if (!environment[PRODUCTION]) return;
    if (clickCount >= 5) return;

    setClickCount((prev) => prev + 1);
  };

  // --------------- Effect handlers ---------------

  useEffect(() => {
    if (clickCount === 5) {
      setSearchParams({ serviceWorkerStatus: "true" });
      setClickCount(0);
    }
  }, [clickCount, setSearchParams]);

  useEffect(() => {
    if (
      newVersionAvailable &&
      ["/login", "/home"].some((path) => pathname.includes(path))
    ) {
      openModal();
    }

    // if (newVersionAvailable && !updateDiscarded && !isQuestionnaire) {
    //   openModal();
    // }
  }, [newVersionAvailable, isQuestionnaire, openModal, pathname]);

  return (
    <>
      <Container>
        {!isOnline && (
          <OfflineAlert>
            <OfflineIcon className='offline-icon' />
            <span>{alert.offline}</span>
          </OfflineAlert>
        )}
        <SnapCheckLogo onClick={onLogoClick} />
        {userAvatarVisible && <UserAvatar />}

        {pathname.includes("login") && <Flags paddingRight />}

        {updateInfoVisible && (
          <UpdateInfo
            isMobile={isMobile}
            updating={updating}
            onClick={onUpdateButtonClick}
          >
            <UpdateIcon className='update-icon' />
            <div className='title'>{title}</div>
          </UpdateInfo>
        )}
      </Container>

      <UpdatePopUp
        open={open}
        updating={updating}
        onUpdateButtonClick={onUpdateButtonClick}
        onDeferButtonClick={onDeferButtonClick}
        // onClose={onCloseModalIconClick}
      />

      <StyledToast position='top-left' theme='colored' autoClose={5000} />
    </>
  );
}

export default TopBar;
