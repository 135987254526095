import { SyntheticEvent } from "react";
import styled, { css } from "styled-components/macro";
import { ImageSize } from "types/types";

const Image = styled.img<{ isLoader: boolean; progress: number }>`
  width: 77%;

  display: block;
  margin: 0 auto;
  transition: width 0.2s linear;

  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;

  user-select: none;

  @media (min-height: 617px) {
    width: 100%;
  }

  ${({ isLoader }) =>
    isLoader &&
    css<{ progress: number }>`
      filter: ${({ progress }) =>
        `saturate(${progress}%) brightness(${progress / 100}) contrast(${
          progress / 2
        }%)`};

      transition: filter ease 0.2s;
    `}
`;

type PreviewImageProps = {
  src: string;
  isLoader?: boolean;
  progress?: number;
  handleLastSlideSize?: (imageSize: ImageSize) => void;
};

function PreviewImage({
  src,
  handleLastSlideSize,
  isLoader = false,
  progress = 0,
}: PreviewImageProps) {
  const onLoad = (e: SyntheticEvent<HTMLImageElement>) => {
    URL.revokeObjectURL(src);

    if (handleLastSlideSize) {
      const { clientWidth: width, clientHeight: height } = e.currentTarget;

      handleLastSlideSize({ width, height });
    }
  };

  return (
    <Image src={src} alt='' onLoad={onLoad} isLoader={isLoader} progress={progress} />
  );
}

export default PreviewImage;
