import { getCookie } from "react-use-cookie";
import styled from "styled-components/macro";

import { FileType } from "types/tasks.types";
import { ACCESS_TOKEN, createHref } from "utils/utils";

import FileIcon from "components/molecules/file-icon/FileIcon";

import { FileLink } from "styles/files.styles";

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FileContainer = styled.div`
  width: 4rem;
  height: 4rem;
  background-color: #fff;
  border-radius: 50%;
  display: grid;
  place-content: center;
`;

function File({ file }: { file: string }) {
  const token = getCookie(ACCESS_TOKEN);
  const href = file ? createHref(file, token) : "";

  if (!file) {
    return null;
  }

  return (
    <Container>
      <FileLink href={href} target='_self' rel='noreferrer'>
        <FileContainer>
          <FileIcon fileType={FileType.PDF} small />
        </FileContainer>
      </FileLink>
    </Container>
  );
}

export default File;
