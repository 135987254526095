import styled, { css } from "styled-components/macro";

export const Container = styled.div`
  ${({ theme }) => theme.fillUpRemainingSpace}
  overflow-y: auto;
  overflow-x: hidden;
`;

export const TasksListContainer = styled.div`
  flex-basis: 90%;
  overflow-y: auto;
`;

export const TasksList = styled.ul`
  overflow: auto;
  ${({ theme }) => theme.scrollbarFirefox}
`;

export const TaskItem = styled.li<{ isMobile: boolean; isMine?: boolean }>`
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.4rem;
  background-color: ${({ theme }) => theme.listItemColor};
  transition: filter 0.2s ease-out;
  cursor: ${({ isMobile }) => (!isMobile ? "pointer" : "none")};

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    filter: brightness(0.97);
  }

  ${({ isMine }) =>
    isMine &&
    css`
      background-color: ${({ theme }) => theme.secondary_200};
    `}
`;

export const TaskItemHeader = styled.div`
  ${({ theme }) => theme.ellipsis};
  max-width: 95%;
  margin-bottom: 1rem;
  font-family: GothamBold;
`;

export const StatusLabelConatiner = styled.div`
  position: absolute;
  right: -0.1rem;
  bottom: -0.5rem;
  height: 2.8rem;
  width: 2.8rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.white};
  display: grid;
  place-content: center;
`;

export const TaskItemFooter = styled.div`
  position: relative;
`;

export const TaskItemDate = styled.div`
  font-size: 1.4rem;

  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  .created {
    display: none;

    @media screen and (min-width: 360px) {
      display: inline;
    }
  }
`;

export const InPropgress = styled.div`
  font-size: 1.4rem;
  background-color: rgba(255, 215, 0, 0.55);
  padding: 0.25rem 1rem;
  font-style: italic;
  border-radius: 0.4rem;

  position: absolute;
  right: 0;
  bottom: 0;
`;

export const NoTasks = styled.div`
  font-size: 1.9rem;
  text-align: center;
  margin-top: 2rem;
  font-style: italic;
`;

export const TasksActions = styled.div`
  flex-basis: 10%;
  display: flex;
  align-items: center;
  padding: 0.5rem 0;

  @media screen and (min-height: 570px) {
    padding: 0;
  }
`;

export const ButtonContainer = styled.div`
  margin-left: auto;
  width: 10rem;

  .add-btn {
    height: 4rem;
    font-size: 1.4rem;
    ${({ theme }) => theme.cardShadow};
  }
`;

export const FilesCount = styled.span`
  display: block;
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.textColor1};
  font-size: 1.2rem;
  display: grid;
  place-content: center;
`;
