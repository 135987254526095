import styled, { css } from "styled-components/macro";

import { device } from "utils/utils";

export const Container = styled.div<{
  imageWidth: number;
  imageHeight: number;
  isMobile: boolean;
  isTextareaFocused: boolean;
}>`
  height: 100%;
  position: relative;

  .swiper {
    position: relative;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
  }

  .swiper-slide {
    ${({ theme }) => theme.fillUpRemainingSpace};
  }

  .preview-image {
    width: 77%;

    display: inline-block;
    margin: 0 auto;
    transition: width 0.2s linear;

    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;

    user-select: none;

    @media (min-height: 617px) {
      width: 100%;
    }
  }

  .swiper-pagination-progressbar-fill {
    background-color: ${({ theme }) => theme.white};
  }

  .swiper-pagination-horizontal {
    width: 77%;
    left: 50%;
    transform: translateX(-50%);

    @media (min-height: 617px) {
      width: 100%;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: ${({ theme }) => theme.white};
    text-shadow: 0px 1px 2px rgba(66, 68, 90, 1);
    top: ${({ imageHeight }) => `${imageHeight / 2}rem`};

    &::after {
      font-size: 3.5rem;

      @media screen and (min-height: 616px) {
        font-size: 4.4rem;
      }
    }

    @media screen and (min-height: 616px) {
      font-size: 4.4rem;
    }

    ${({ isTextareaFocused }) =>
      isTextareaFocused &&
      css`
        visibility: hidden;
      `};
  }

  .swiper-button {
    &-prev {
      ${({ isMobile }) =>
        isMobile &&
        css<{ imageWidth: number }>`
          margin-left: ${({ imageWidth }) =>
            `${Math.trunc((window.innerWidth - imageWidth * 10 - 47) / 2 / 10)}rem`};
        `}

      @media ${device.iPad} {
        margin-left: 0;
      }
    }

    &-next {
      ${({ isMobile }) =>
        isMobile &&
        css<{ imageWidth: number }>`
          margin-right: ${({ imageWidth }) =>
            `${Math.trunc((window.innerWidth - imageWidth * 10 - 47) / 2 / 10)}rem`};
        `}

      @media ${device.iPad} {
        margin-right: 0;
      }
    }
  }

  .comment {
    max-height: 6rem;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 1rem 2rem 1rem 1rem;
    background-color: ${({ theme }) => theme.white};
    position: relative;
    border-radius: 0.4rem;
    margin-top: 0.8rem;
    font-size: 1.8rem;
    cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};

    @media screen and (min-height: 616px) {
      max-height: 8rem;
    }
  }

  .more-icon {
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    top: ${({ imageHeight }) => `${imageHeight + 1.6}rem`};
    right: 0.1rem;
    cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};
  }
`;

export const BinContainer = styled.div<{
  imageWidth: number;
  isMobile: boolean;
}>`
  width: 4.2rem;
  height: 4.2rem;
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;

  ${({ theme }) => theme.iconBackground};
  z-index: ${({ theme }) => theme.level1};
  cursor: pointer;

  ${({ isMobile }) =>
    isMobile &&
    css<{ imageWidth: number }>`
      right: ${({ imageWidth }) =>
        `${Math.trunc((window.innerWidth - imageWidth * 10) / 2 / 10) - 1}rem`};
    `}

  @media screen and (min-height: 616px) {
    top: 1rem;
  }

  @media ${device.iPad} {
    right: 1rem;
  }

  .bin {
    width: 2.5rem;
    height: 2.5rem;

    path {
      stroke: ${({ theme }) => theme.white};
    }

    z-index: ${({ theme }) => theme.level1};
  }
`;

export const PopUpMenuContainer = styled.div<{
  imageHeight: number;
}>`
  width: 100%;
  position: absolute;
  top: ${({ imageHeight }) => `${imageHeight + 5.2}rem`};
  left: 0;
  background-color: ${({ theme }) => theme.primary};
  z-index: ${({ theme }) => theme.level2};
  ${({ theme }) => theme.cardShadow};
  border-radius: 0.4rem;

  @media screen and (min-height: 616px) {
    top: ${({ imageHeight }) => `${imageHeight + 5.8}rem`};
  }

  @media ${device.iPad} {
    width: 50%;
  }
`;

export const PopUpMenuContent = styled.div`
  position: relative;
  padding: 1rem 0 1rem 1rem;

  .close-icon {
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    fill: ${({ theme }) => theme.aliceBlue};
    cursor: pointer;
  }
`;

export const MenuOption = styled.div`
  width: 80%;
  color: ${({ theme }) => theme.aliceBlue};
  user-select: none;
  padding: 0.7rem;
  cursor: pointer;
  transition: background-color 0.15s ease-out;

  &:hover {
    background-color: ${({ theme }) => theme.royalBlue};
    border-radius: 0.4rem;
  }
`;

export const CommentInput = styled.div`
  margin-top: 0.8rem;
  overflow: hidden;
  border-radius: 0.4rem;
`;

export const CommentPlaceHolder = styled.div`
  height: 4.8rem;
  overflow: hidden;
`;

export const CommentReply = styled.button`
  width: 100%;
  height: 100%;
  text-align: initial;
  background-color: ${({ theme }) => theme.white};
  border-radius: 0.4rem;
  border: none;
  outline: none;
  font: inherit;
  text-transform: inherit;
  color: rgba(33, 47, 91, 0.7);

  .label {
    padding-left: 1rem;
  }
`;

export const CommentTextarea = styled.textarea`
  width: 95.5%;
  min-height: 8.4rem;
  padding: 1rem;
  appearance: none;
  resize: none;
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.white};
  display: block;
  -webkit-border-radius: 0.4rem;
  -moz-border-radius: 0.4rem;
  border-radius: 0.4rem;
  font-size: 1.8rem;

  &::placeholder {
    color: rgba(33, 47, 91, 0.7);
  }

  &:focus {
    outline: none;
  }
`;

export const CommentButtons = styled.div<{ isMobile: boolean }>`
  width: 100%;
  padding: 1rem 0;
  background-color: ${({ theme }) => theme.primary_50};
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: ${({ theme }) => theme.level3};
`;

export const CancelSaveButtons = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  width: 10rem;

  &:first-child {
    margin-right: 1rem;
  }

  .comment-btn {
    height: 4rem;
    font-size: 1.4rem;
    ${({ theme }) => theme.cardShadow};
  }

  .cancel {
    background-color: ${({ theme }) => theme.greyDark};
    color: ${({ theme }) => theme.primary};
  }
`;
