import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { UserOption } from "types/assign-to-user.types";
import { getTranslations } from "../../translations/selected-task.translations";

import ShimmerLoader from "components/atoms/loaders/ShimmerLoader";

import { StyledLabel } from "styles/generalStyles";

const Container = styled.div``;

const UserContainer = styled.div`
  background-color: ${({ theme }) => theme.white};
  position: relative;
  border-radius: 0.4rem;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(166, 181, 211, 0.15);
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 0.4rem;
`;

const User = styled.div`
  padding-left: 1rem;
`;

type UserCreatedProps = {
  userCreated: UserOption | null;
  isLoading: boolean;
};

function UserCreated({ userCreated, isLoading }: UserCreatedProps) {
  const {
    i18n: { language },
  } = useTranslation();

  const { labels } = getTranslations(language);

  let content = null;

  if (userCreated) {
    content = (
      <UserContainer>
        <Overlay />
        <User>{userCreated.label}</User>
      </UserContainer>
    );
  }

  if (isLoading) {
    content = <ShimmerLoader />;
  }

  return (
    <Container>
      <StyledLabel>{labels.userCreated}</StyledLabel>
      {content}
    </Container>
  );
}

export default UserCreated;
