import { PermFields } from "types/permissions.types";
import { RoleNames } from "types/roles.types";

// ***** COMMON *****

const commonPermittedInit = {
  ALL: false,
  [PermFields.ID]: false,
  [PermFields.STATUS]: false,
  [PermFields.USER_CREATED]: false,
  [PermFields.USER_UPDATED]: false,
  [PermFields.DATE_CREATED]: false,
  [PermFields.DATE_UPDATED]: false,
};

const machProcPermittedInit = {
  [PermFields.SERIAL_NUMBER]: false,
  [PermFields.TAGS]: false,
  [PermFields.DEPARTMENT]: false,
  [PermFields.LAST_ASSESSMENT]: false,
  [PermFields.SERVICE_EXPIRY_DATE]: false,
  [PermFields.MISC]: false,
  [PermFields.ASSESSMENTS]: false,
};

// ***************

const departmentsPermittedInit = {
  ...commonPermittedInit,
  [PermFields.SORT]: false,
  [PermFields.DEPARTMENT_NAME]: false,
  [PermFields.COMPANY]: false,
};

const tasksPermittedInit = {
  ...commonPermittedInit,
  [PermFields.ASSIGN_TO]: false,
  [PermFields.DESCRIPTION]: false,
  [PermFields.FILES]: false,
  [PermFields.RELATED]: false,
  [PermFields.SORT]: false,
  [PermFields.TITLE]: false,
  [PermFields.DEADLINE]: false,
};

const machinesPermittedInit = {
  ...commonPermittedInit,
  ...machProcPermittedInit,
  [PermFields.MACHINE_MODEL]: false,
  [PermFields.MACHINE_TYPE]: false,
  [PermFields.ACCORDION]: false,
  [PermFields.SOME_RAW_GROUP]: false,
  [PermFields.MAKE]: false,
  [PermFields.MAKE_YEAR]: false,
  [PermFields.HOUR_METER]: false,
  [PermFields.DOCUMENTS]: false,
};

const processesPermittedInit = {
  ...commonPermittedInit,
  ...machProcPermittedInit,
  [PermFields.PROCESS_NAME]: false,
  [PermFields.PROCESS_TYPE]: false,
  [PermFields.ADDRESS]: false,
  [PermFields.GEOLOCATION]: false,
};

const departmentsPermissionsInit = {
  PERMITTED_TO_CREATE: departmentsPermittedInit,
  PERMITTED_TO_READ: departmentsPermittedInit,
  PERMITTED_TO_UPDATE: departmentsPermittedInit,
  PERMITTED_TO_DELETE: departmentsPermittedInit,
  PERMITTED_TO_SHARE: departmentsPermittedInit,
};

const tasksPermissionsInit = {
  PERMITTED_TO_CREATE: tasksPermittedInit,
  PERMITTED_TO_READ: tasksPermittedInit,
  PERMITTED_TO_UPDATE: tasksPermittedInit,
  PERMITTED_TO_DELETE: tasksPermittedInit,
  PERMITTED_TO_SHARE: tasksPermittedInit,
};

const machinesPermissionsInit = {
  PERMITTED_TO_CREATE: machinesPermittedInit,
  PERMITTED_TO_READ: machinesPermittedInit,
  PERMITTED_TO_UPDATE: machinesPermittedInit,
  PERMITTED_TO_DELETE: machinesPermittedInit,
  PERMITTED_TO_SHARE: machinesPermittedInit,
};

const processesPermissionsInit = {
  PERMITTED_TO_CREATE: processesPermittedInit,
  PERMITTED_TO_READ: processesPermittedInit,
  PERMITTED_TO_UPDATE: processesPermittedInit,
  PERMITTED_TO_DELETE: processesPermittedInit,
  PERMITTED_TO_SHARE: processesPermittedInit,
};

export const PERMISSIONS_INIT = {
  TASKS_PERMISSIONS: tasksPermissionsInit,
  MACHINES_PERMISSIONS: machinesPermissionsInit,
  PROCESSES_PERMISSIONS: processesPermissionsInit,
  DEPARTMENTS_PERMISSIONS: departmentsPermissionsInit,
};

const { ADMINISTRATOR, INCOGNITO, LEADER, MANAGER, OPERATOR } = RoleNames;

const Roles = [
  { id: "1a103af5-e22c-4f63-a97c-71d26bfcccdf", name: ADMINISTRATOR },
  { id: "acc30aea-48f2-4e50-8f64-86ebd47205a7", name: INCOGNITO },
  { id: "4afcd110-2193-4706-b190-f1a4b83771fe", name: LEADER },
  { id: "10c159b5-33a7-4550-bb19-ddfa8ce78796", name: MANAGER },
  { id: "02a81453-5cbf-4608-b372-6db320c3092f", name: OPERATOR },
];

export const getRoleName = (roleId: string) =>
  Roles.find((role) => role.id === roleId)?.name;
