import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components/macro";

import { ContentContainer, Header } from "styles/generalStyles";

import Button from "components/atoms/Button";
import BackArrow from "components/atoms/BackArrow";

const Container = styled.div`
  ${({ theme }) => theme.fillUpRemainingSpace}
  position: relative;
  justify-content: space-between;
`;

const Main = styled.div``;

const CamerasList = styled.ul`
  margin-top: 1.5rem;
`;

const CameraItem = styled.li<{ border?: boolean }>`
  padding: 2rem;
  background-color: ${({ theme }) => theme.glacier};
  color: ${({ theme }) => theme.aliceBlue};
  border-radius: 0.4rem;
  transition: opacity 0.2s ease-out;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  &:hover {
    opacity: 0.9;
  }

  ${({ border }) =>
    border &&
    css`
      border: 6px solid ${({ theme }) => theme.successColor};
    `}
`;

const ButtonContainer = styled.div`
  margin-top: 4.5rem;
`;

const PhotoContainer = styled.div`
  border: 4px solid ${({ theme }) => theme.primary};
  border-radius: 0.6rem;
  height: 20rem;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  background-color: #dce0e9;
  position: relative;

  &::after {
    content: "Photo preview";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .photo {
    height: 100%;
    max-width: 100%;
    z-index: 10;
  }
`;

const VideoContainer = styled.div`
  border: 4px solid ${({ theme }) => theme.secondary};
  border-radius: 0.6rem;
  height: 20rem;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  background-color: #dce0e9;
  position: relative;

  &::after {
    content: "Video preview";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .video {
    height: 100%;
    max-width: 100%;
    z-index: 10;
  }
`;

const Actions = styled.div`
  /* width: 100%; */
  /* border: 1px solid teal; */

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.75rem;
`;

const CaptureButtons = styled.div``;

const CaptureButtonContainer = styled.div`
  flex-basis: 49%;
  /* border: 2px solid red; */
`;

const CaptureBtn = styled.label`
  background-color: ${({ theme }) => theme.primary};
  padding: 1rem;
  width: 100%;
  display: block;
  border-radius: 0.4rem;
  color: #fff;
  text-align: center;
  cursor: pointer;

  &.video {
    background-color: ${({ theme }) => theme.secondary};
  }

  &.attachment {
    background-color: ${({ theme }) => theme.ceruleanBlue};
  }
`;

function CameraTest() {
  const [src, setSrc] = useState("");
  const [videoSrc, setVideoSrc] = useState("");
  const [attachmentSrc, setAttachmentSrc] = useState("");
  const navigate = useNavigate();

  let photoObjectURL = "";
  let videoObjectURL = "";
  let attachmentObjectURL = "";

  useEffect(() => {
    if (src) {
      URL.revokeObjectURL(photoObjectURL);
    }
  }, [src, photoObjectURL]);

  useEffect(() => {
    if (videoSrc) {
      URL.revokeObjectURL(videoObjectURL);
    }
  }, [videoSrc, videoObjectURL]);

  useEffect(() => {
    if (attachmentSrc) {
      URL.revokeObjectURL(attachmentObjectURL);
    }
  }, [attachmentSrc, attachmentObjectURL]);

  return (
    <ContentContainer>
      <Container>
        <Main>
          <Header standardFont>
            <BackArrow
              onClick={() => {
                navigate(-1);
              }}
            />
            <span>📷 Test </span>
          </Header>

          <PhotoContainer>
            <img src={src} alt='' className='photo' />
          </PhotoContainer>

          {/* <embed src={attachmentSrc} width='250' height='200' /> */}

          <VideoContainer>
            <video src={videoSrc} controls={!!videoSrc} className='video' />
          </VideoContainer>
        </Main>

        <Actions>
          <CaptureButtonContainer>
            <CaptureBtn htmlFor='photo'>Take Photo</CaptureBtn>
            <input
              type='file'
              id='photo'
              capture='environment'
              accept='image/*'
              onChange={(e) => {
                if (e.target.files) {
                  const photo = e.target.files[0];
                  photoObjectURL = URL.createObjectURL(photo);

                  setSrc(photoObjectURL);
                }
              }}
              style={{ visibility: "hidden", width: 0, height: 0, position: "absolute" }}
            />
          </CaptureButtonContainer>

          <CaptureButtonContainer>
            <CaptureBtn htmlFor='video' className='video'>
              Record Video
            </CaptureBtn>
            <input
              type='file'
              id='video'
              capture='environment'
              accept='video/*'
              onChange={(e) => {
                if (e.target.files) {
                  const video = e.target.files[0];
                  videoObjectURL = URL.createObjectURL(video);

                  setVideoSrc(videoObjectURL);
                }
              }}
              style={{ visibility: "hidden", width: 0, height: 0, position: "absolute" }}
            />
          </CaptureButtonContainer>
        </Actions>
      </Container>
    </ContentContainer>
  );
}

export default CameraTest;
