import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { CountryCodes } from "types/types";
import { AlertNames } from "../types/summary.types";

import ConfirmationCheckbox from "./ConfirmationCheckbox";

const Container = styled.div``;

const InfoContainer = styled.div`
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.75);
  border: 2px solid ${({ theme }) => theme.primary_400};
  border-radius: 0.6rem;
  font-size: 1.6rem;
`;

type TermsAcceptBannerProps = {
  termsAccept: string | null;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const { TERMS } = AlertNames;

function TermsAcceptBanner({ termsAccept, onChange }: TermsAcceptBannerProps) {
  const {
    i18n: { language },
  } = useTranslation();

  const label = language.includes(CountryCodes.PL) ? "Akceptuję" : "I accept";

  return (
    <Container>
      <InfoContainer>{termsAccept}</InfoContainer>
      <ConfirmationCheckbox label={label} onChange={onChange} name={TERMS} />
    </Container>
  );
}

export default TermsAcceptBanner;
