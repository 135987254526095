import styled from "styled-components/macro";

import useTimeout from "hooks/useTimeout";

import UploadProgressBar from "components/atoms/upload-progress-bar/UploadProgressBar";
import PreviewImage from "../preview-image/PreviewImage";
import { useEffect, useState } from "react";

const Container = styled.div`
  position: relative;
`;

const ProgressContainer = styled.div`
  width: 95%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

type PhotoLoaderProps = {
  progress: number;
  photoFile: File | undefined;
};

function PhotoLoader({ progress, photoFile }: PhotoLoaderProps) {
  const [progressVisible, setProgressVisible] = useState(false);
  const setTimeOut = useTimeout();

  let objectUrl = "";

  if (photoFile) {
    objectUrl = URL.createObjectURL(photoFile);
  }

  useEffect(() => {
    setTimeOut(() => {
      setProgressVisible(true);
    }, 500);
  }, [setTimeOut]);

  return (
    <Container>
      {objectUrl && <PreviewImage src={objectUrl} progress={progress} isLoader />}
      {progressVisible && (
        <ProgressContainer>
          <UploadProgressBar progress={progress} />
        </ProgressContainer>
      )}
    </Container>
  );
}

export default PhotoLoader;
