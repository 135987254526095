import { InfoLine, Label, Value } from "../../styles/task-related.styles";

function SerialNumber({ label, serialNumber }: { label: string; serialNumber: string }) {
  return (
    <InfoLine>
      <Label>{label}:</Label>
      <Value>{serialNumber}</Value>
    </InfoLine>
  );
}

export default SerialNumber;
