import { CountryCodes } from "types/types";

export const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let header = {
    title: "Task > related",
    assesment: "Assessment",
    alarm: "Alarm",
    machine: "Machine",
    process: "Process",
  };

  if (isPolish) {
    header = {
      title: "Zadanie > powiązane",
      assesment: "Kwestionariusz",
      alarm: "Alarm",
      machine: "Maszyna",
      process: "Proces",
    };
  }

  return { header };
};
