import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { isMobile } from "utils/utils";
import { getTranslations } from "translations/related.translations";

import { RelatedItem as RelatedItemType, RelatedCollection } from "types/tasks.types";

const Container = styled.div``;

const Item = styled.div<{ isMobile: boolean }>`
  border-radius: 0.6rem;
  padding: 0.5rem;
  user-select: none;
  background-color: #fff;
  cursor: ${({ isMobile }) => (!isMobile ? "pointer" : "none")};
`;

const ItemHeader = styled.div`
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  ${({ theme }) => theme.ellipsis}
`;

const ItemTitle = styled.div`
  ${({ theme }) => theme.ellipsis}
  font-size: 1.3rem;
  max-width: 16rem;
`;

type RelatedItemProps = {
  relatedItem: RelatedItemType;
};

const { ASSESSMENTS, MACHINES, PROCESSES, STATEMENTS } = RelatedCollection;

function RelatedItemContent({ relatedItem }: RelatedItemProps) {
  const {
    i18n: { language },
  } = useTranslation();

  const { header } = getTranslations(language);

  let content = null;

  switch (relatedItem.collection) {
    case ASSESSMENTS:
      content = (
        <Item isMobile={isMobile}>
          <ItemHeader>{header.assesment}</ItemHeader>
          <ItemTitle>
            {relatedItem?.item?.Questionnaire?.QuestionnaireTitle ?? "---"}
          </ItemTitle>
        </Item>
      );
      break;
    case STATEMENTS:
      content = (
        <Item isMobile={isMobile}>
          <ItemHeader>{header.alarm}</ItemHeader>
          <ItemTitle>{relatedItem?.item?.AccidentForm?.Title ?? "---"}</ItemTitle>
        </Item>
      );
      break;
    case MACHINES:
      content = (
        <Item isMobile={isMobile}>
          <ItemHeader>{header.machine}</ItemHeader>
          <ItemTitle>{relatedItem?.item?.MachineModel ?? "---"}</ItemTitle>
        </Item>
      );
      break;
    case PROCESSES:
      content = (
        <Item isMobile={isMobile}>
          <ItemHeader>{header.process}</ItemHeader>
          <ItemTitle>{relatedItem?.item?.ProcessName ?? "---"}</ItemTitle>
        </Item>
      );
  }

  return <Container>{content}</Container>;
}

export default RelatedItemContent;
