import { useEffect, useState } from "react";

import useTimeout from "hooks/useTimeout";
import useInterval from "hooks/useInterval";
import useNotification from "hooks/useNotification";
import useUpdateDefer from "./useUpdateDefer";
import { isIOS, reloadPage } from "utils/utils";

type SWReg = ServiceWorkerRegistration | undefined;

const getSWRegistration = () => {
  return navigator.serviceWorker.getRegistration();
};

function useServiceWorker() {
  const [swRegistration, setSwRegistration] = useState<SWReg>();
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  const [updating, setUpdating] = useState(false);
  const { removeReminderFromStorage, isUpdateDeferred, shouldDisplayPopUp } =
    useUpdateDefer();
  const notify = useNotification();

  const setTimeOut = useTimeout();
  const delay = isIOS ? 2000 : 1000;

  const handleUpdate = () => {
    if (swRegistration && swRegistration.waiting) {
      try {
        swRegistration.waiting.postMessage({ type: "SKIP_WAITING" });
        removeReminderFromStorage();

        setTimeOut(() => {
          reloadPage();
        }, delay);
      } catch (e) {
        notify("Błąd aktualizacji", "error");
      }
    }

    // setTimeOut(() => {
    //   window.location.reload();
    // }, 1000);
  };

  const checkIfUpdateAvailable = () => {
    getSWRegistration().then((reg) => {
      setSwRegistration(reg);

      if (reg?.waiting) {
        if (isUpdateDeferred) {
          if (shouldDisplayPopUp) {
            setNewVersionAvailable(true);
          }
        } else {
          setNewVersionAvailable(true);
        }
      }
    });
  };

  useInterval(() => {
    checkIfUpdateAvailable();
  }, 1000 * 60 * 1);

  useEffect(() => {
    setTimeOut(() => {
      checkIfUpdateAvailable();
    }, 6000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    swRegistration,
    setNewVersionAvailable,
    newVersionAvailable,
    updating,
    setUpdating,
    handleUpdate,
  };
}

export default useServiceWorker;
