import { ReactNode } from "react";
import styled from "styled-components/macro";

import { Card } from "styles/generalStyles";
import { InfoLine, Label, Value } from "../../styles/task-related.styles";

const ItemContent = styled.div`
  margin-top: 1rem;
`;

type ContentProps = {
  header: string;
  title: string;
  children?: ReactNode;
};

function Content({ header, title, children }: ContentProps) {
  return (
    <Card>
      <ItemContent>
        <InfoLine vertical>
          <Label>{header}</Label>
          <Value>{title}</Value>
        </InfoLine>

        {children}
      </ItemContent>
    </Card>
  );
}

export default Content;
