import { useContext, useEffect, useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useModal from "hooks/useModal";
import useReturnPaths from "hooks/useReturnPaths";
import { ReturnPaths } from "types/return-paths.types";
import UserContext from "contexts/user-context/UserContext";
import { getTranslations } from "./translations/profile.translations";
import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";

import UserInfo from "./components/user-info/UserInfo";

import { ContentContainer } from "styles/generalStyles";
import { InfoCard } from "./styles/profile.styles";
import ProfileHeader from "./components/profile-header/ProfileHeader";
import DepartmentsEdit from "./components/departments-edit/DepartmentsEdit";
import ConfirmationModal from "components/organisms/ConfirmationModal";
import ModalMessage from "./components/modal-message/ModalMessage";

const { PROFILE_RETURN_PATH } = ReturnPaths;

enum IconClicked {
  CLOSE = "close",
  ARROW = "arrow",
}

const { ARROW, CLOSE } = IconClicked;

function Profile() {
  const {
    user,
    isError,
    isLoading,
    refetchUserInfo,
    PERMISSIONS: { DEPARTMENTS_PERMISSIONS },
  } = useContext(UserContext);
  const {
    state: { selectedQuestionnaire },
    fetchAccidentForms,
  } = useContext(QuestionnairesContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const [clickCount, setClickCount] = useState(0);
  const [isDirty, setIsDirty] = useState(false);
  const [iconClicked, setIconClicked] = useState<IconClicked>();
  const navigate = useNavigate();
  const { handleClosePage } = useReturnPaths();
  const { open, openModal, closeModal } = useModal();
  const {
    i18n: { language },
  } = useTranslation();
  const {
    departments: { modal },
  } = getTranslations(language);

  const infoCardRef = useRef<HTMLDivElement>(null);

  const editDepartmentsParams = searchParams.get("edit-departments");
  const isDepartmentsEditVisible = !!editDepartmentsParams;
  const isChecklistInUse = !!selectedQuestionnaire;

  // *************** PERMISSIONS ***************

  const { PERMITTED_TO_READ, PERMITTED_TO_UPDATE } = DEPARTMENTS_PERMISSIONS;

  // ----- READ -----
  const permittedToReadAll = PERMITTED_TO_READ.ALL;
  const permittedToReadCompany = permittedToReadAll || PERMITTED_TO_READ.Company;
  const permittedToReadDeptName = permittedToReadAll || PERMITTED_TO_READ.DepartmentName;

  // ----- UPDATE -----
  const permittedToUpdateAll = PERMITTED_TO_UPDATE.ALL;

  // --------------- On click handlers ---------------

  const handleIconClick = (icon: IconClicked) => {
    setIconClicked(icon);
    openModal();
  };

  const handleBackArrowClick = () => {
    const containerHeight = searchParams.get("containerHeight") as string;
    setSearchParams({ containerHeight });
  };

  const handleCloseIconClick = () => {
    handleClosePage(PROFILE_RETURN_PATH);
  };

  const onDepartmentsClick = () => {
    if (!permittedToUpdateAll || isChecklistInUse) return;

    const containerHeight = searchParams.get("containerHeight") as string;
    setSearchParams({ containerHeight, "edit-departments": "true" });
  };

  const onBackArrowClick = () => {
    if (isDirty) {
      handleIconClick(IconClicked.ARROW);
    } else {
      handleBackArrowClick();
    }
  };

  const onCloseIconClick = () => {
    if (isDirty) {
      handleIconClick(IconClicked.CLOSE);
    } else {
      handleCloseIconClick();
    }
  };

  const onConfirmationClick = () => {
    closeModal();

    switch (iconClicked) {
      case ARROW:
        handleBackArrowClick();
        break;
      case CLOSE:
        handleCloseIconClick();
    }
  };

  const onVersionClick = () => {
    setClickCount((prev) => prev + 1);
  };
  // --------------- Effect handler ---------------

  useEffect(() => {
    if (clickCount === 5) {
      navigate("/device-info");
    }
  }, [clickCount, navigate]);

  useEffect(() => {
    if (!infoCardRef.current) return;

    const containerHeight = `${infoCardRef.current.offsetHeight}`;
    let searchParamsObject = {};

    if (editDepartmentsParams) {
      searchParamsObject = { containerHeight, "edit-departments": "true" };
    } else {
      searchParamsObject = { containerHeight };
    }

    setSearchParams(searchParamsObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentContainer>
      <InfoCard ref={infoCardRef}>
        <ProfileHeader onCloseIconClick={onCloseIconClick} />
        <UserInfo
          user={user}
          isError={isError}
          isLoading={isLoading}
          isVisible={!isDepartmentsEditVisible}
          isChecklistInUse={isChecklistInUse}
          onVersionClick={onVersionClick}
          onDepartmentsClick={onDepartmentsClick}
          permittedToUpdateAll={permittedToUpdateAll}
          permittedToReadCompany={permittedToReadCompany}
          permittedToReadDeptName={permittedToReadDeptName}
        />
        <DepartmentsEdit
          user={user}
          setIsDirty={setIsDirty}
          isVisible={isDepartmentsEditVisible}
          onBackArrowClick={onBackArrowClick}
          refetchUserInfo={refetchUserInfo}
          fetchAccidentForms={fetchAccidentForms}
        />
      </InfoCard>

      <ConfirmationModal
        message={<ModalMessage />}
        onClick={onConfirmationClick}
        onClose={closeModal}
        open={open}
        buttonLabel={modal.button.label}
      />
    </ContentContainer>
  );
}

export default Profile;
