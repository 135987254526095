import { ChangeEvent, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "react-query";
import { AxiosError, AxiosProgressEvent } from "axios";
import { getCookie } from "react-use-cookie";
import { useTranslation } from "react-i18next";
import { differenceInMilliseconds } from "date-fns";

import {
  Assessment,
  QuestionnairePayload,
  QUESTION_TYPE_IDS,
  Answers,
  SendQuestionnaireResponse,
  FormTypes,
  Answer,
} from "types/types";
import { AlertNames } from "./types/summary.types";
import { ACCESS_TOKEN, DEVICE_INFO_STRING, checkIfPhotoType } from "utils/utils";
import { sendQuestionnaire } from "api/questionnaires";
import AuthContext from "contexts/auth-context/AuthContext";
import UserContext from "contexts/user-context/UserContext";
import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";
import useModal from "hooks/useModal";
import useScreen from "hooks/useScreen";
import useTimeout from "hooks/useTimeout";
import useNotification from "hooks/useNotification";
import useHandleBackToHomePage from "hooks/useHandleBackToHomePage";
import { useAppDispatch } from "redux/hooks/hooks";
import usePhotosForAnswers from "hooks/usePhotosForAnswers";
import useAdditionalOptionsAccess from "hooks/useAdditionalOptionsAccess";
import packageInfo from "../../../package.json";
import { setAssessmentResponse } from "redux/slices/assessmentSlice";
import { getCriticalAnswersNumber } from "./utils/summary.utils";

import { ReactComponent as ChevronIcon } from "assets/icons/navigation-chevron.svg";

import Button from "components/atoms/Button";
import ConfirmationModal from "components/organisms/ConfirmationModal";
import Statistics from "./components/Statistics";
import GravityAlert from "./components/GravityAlert";
import EntityInfo from "components/molecules/EntityInfo";
import ReportIncompleteInfo from "./components/ReportIncompleteInfo";
import SuccessAnimated from "components/molecules/success-animated/SuccessAnimated";
import UploadProgressBar from "components/atoms/upload-progress-bar/UploadProgressBar";
import TermsAcceptBanner from "./components/TermsAcceptBanner";

import { ContentContainer, Wrapper } from "styles/generalStyles";
import { ModalStyles } from "styles/generalStyles";
import {
  Header,
  Arrow,
  SummaryLabel,
} from "pages/questionnaire/styles/questionnaireStyles";
import { ActionsWrapper, SummaryLabelContent } from "./styles/summaryStyles";

const { Actions, ButtonContainer } = ModalStyles;

const { YES_NO, YES_NO_TEXT, YES_NO_PHOTO } = QUESTION_TYPE_IDS;
const { YES, NO, NA } = Answers;
const { QUESTIONNAIRE } = FormTypes;
const { GRAVITY, TERMS } = AlertNames;

function Summary() {
  const {
    state: { answers, machineProcess, serialNumber, startTime, selectedQuestionnaire },
    numberOfQuestions,
  } = useContext(QuestionnairesContext);
  const { setShouldReAuth } = useContext(AuthContext);
  const {
    labels: { gravityAlertInfo, termsAccept },
  } = useContext(UserContext);
  const [completedQuestionnaire, setCompletedQuestionnaire] = useState<Assessment>();
  const [percentage, setPercentage] = useState({ positive: 0, negative: 0 });
  const [gravityAlertConfirmed, setGravityAlertConfirmed] = useState(false);
  const [termsAcceptConfirmed, setTermsAcceptConfirmed] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const { photosForAnswers } = usePhotosForAnswers(QUESTIONNAIRE);
  const { hasAccessToAdditionalOptions } = useAdditionalOptionsAccess();

  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation(["summary", "common"], { useSuspense: true });
  const setTimeOut = useTimeout();

  const criticalAnswersNumber = getCriticalAnswersNumber(answers);
  const reportSent = !!searchParams.get("assessment");

  const gravityAlertVisible =
    !reportSent && !!criticalAnswersNumber && !!gravityAlertInfo;
  const termsAcceptVisible = !reportSent && !!termsAccept;

  const Machine = machineProcess?.Machine ? machineProcess?.Machine.id : null;
  const Process = machineProcess?.Process ? machineProcess?.Process.id : null;

  const navigate = useNavigate();

  const { open, openModal, closeModal } = useModal();
  const { smallView } = useScreen();
  const deviceInfoString = localStorage.getItem(DEVICE_INFO_STRING);

  const { handleBackToHomePage } = useHandleBackToHomePage();
  const notify = useNotification();
  const dispatch = useAppDispatch();
  const token = getCookie(ACCESS_TOKEN);
  const endTimeRef = useRef<Date>(new Date());

  const allQuestionsAnswered = numberOfQuestions === answers.length;

  let summaryLabel = (
    <SummaryLabelContent>{t("header.report-incomplete")}</SummaryLabelContent>
  );

  if (allQuestionsAnswered) {
    summaryLabel = (
      <SummaryLabelContent>{t("header.report-complete")}</SummaryLabelContent>
    );
  }

  // --------------- On success handler ---------------

  const onSuccess = (data: SendQuestionnaireResponse) => {
    const { assessment } = data;

    setSearchParams({ assessment });
    dispatch(setAssessmentResponse(data));
  };

  // --------------- On error handler ---------------

  const onError = (error: AxiosError) => {
    const { response, message } = error;

    if (response?.status === 401) {
      setShouldReAuth(true);
    } else {
      notify(message, "error");
    }
  };

  // --------------- API request ---------------

  const { mutate, isLoading } = useMutation<
    SendQuestionnaireResponse,
    AxiosError,
    QuestionnairePayload
  >(sendQuestionnaire, {
    onSuccess,
    onError,
  });

  // --------------- API handler ---------------

  const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
    const value = Math.ceil((progressEvent.progress as number) * 100);

    setUploadProgress(value);
  };

  const handleSendAssessment = () => {
    if (!endTimeRef.current) {
      return;
    }

    const Result = +percentage.positive.toFixed(2);
    const AppVersion = packageInfo.version;
    const UserAgent = deviceInfoString;
    const Duration = Math.round(
      differenceInMilliseconds(endTimeRef.current, startTime) / 1000,
    );

    const assessmentPayload = {
      payload: {
        ...completedQuestionnaire,
        AppVersion,
        Duration,
        Machine,
        Process,
        Result,
        UserAgent,
      },
      token,
      onUploadProgress,
    } as QuestionnairePayload;

    mutate(assessmentPayload);
  };

  // --------------- Navigation handlers ---------------

  const goToLastQuestion = () => {
    const path = `/questionnaire/${selectedQuestionnaire?.id}/${numberOfQuestions}`;

    navigate(path, {
      replace: true,
    });
  };

  const navigateToEndScreenStepper = useCallback(() => {
    const pathname = "/additional-options";
    const search = "?step=1";

    navigate({
      pathname,
      search,
    });
  }, [navigate]);

  // --------------- Unanswered questions handlers ---------------

  const findFirstUnansweredQuestion = () => {
    if (!selectedQuestionnaire) {
      return;
    }

    const unansweredQuestions = selectedQuestionnaire.Questions.map((question) =>
      answers.some((answer) => answer.Question === question.id) ? undefined : question,
    ).filter((item) => !!item);

    const firstUnansweredQuestion = !!unansweredQuestions
      ? unansweredQuestions[0]
      : undefined;

    return firstUnansweredQuestion;
  };

  const goToFirstUnansweredQuestion = (index: number) => {
    const path = `/questionnaire/${selectedQuestionnaire?.id}/${index + 1}`;
    navigate(path);
  };

  const handleUnansweredQuestions = () => {
    if (!selectedQuestionnaire) {
      return;
    }

    const firstUnansweredQuestion = findFirstUnansweredQuestion();

    let firstUnansweredQuestionIndex = 0;

    if (firstUnansweredQuestion) {
      firstUnansweredQuestionIndex = selectedQuestionnaire.Questions.findIndex(
        ({ id }) => id === firstUnansweredQuestion.id,
      );
    }

    goToFirstUnansweredQuestion(firstUnansweredQuestionIndex);
  };

  // --------------- On click handlers ---------------

  const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    switch (e.target.name) {
      case GRAVITY:
        setGravityAlertConfirmed((prev) => !prev);
        break;
      case TERMS:
        setTermsAcceptConfirmed((prev) => !prev);
    }
  };

  const onArrowBackClick = () => {
    if (isLoading) {
      return;
    }

    if (reportSent) {
      handleBackToHomePage();
    } else {
      goToLastQuestion();
    }
  };

  const onCompleteBtnClick = () => {
    handleUnansweredQuestions();
  };

  // --------------- Effect handlers ---------------

  useEffect(() => {
    if (!photosForAnswers) {
      return;
    }

    const filteredAnswers = answers.filter(
      ({ QuestionType, Value }) =>
        (QuestionType === YES_NO ||
          QuestionType === YES_NO_TEXT ||
          QuestionType === YES_NO_PHOTO) &&
        Value !== NA,
    );

    let positiveCount = 0;
    let negativeCount = 0;

    const positiveVsNegative = filteredAnswers.reduce((acc, curr) => {
      const { Value } = curr;

      if (Value === YES) {
        positiveCount += 1;
        acc = { ...acc, positiveAnswers: positiveCount };
      }
      if (Value === NO) {
        negativeCount += 1;
        acc = { ...acc, negativeAnswers: negativeCount };
      }

      return acc;
    }, {} as { positiveAnswers: number; negativeAnswers: number });

    const { positiveAnswers, negativeAnswers } = positiveVsNegative;
    const getPercentage = (value: number) => (value / filteredAnswers.length) * 100 || 0;

    setPercentage({
      positive: getPercentage(positiveAnswers),
      negative: getPercentage(negativeAnswers),
    });

    // Complete answers with photos (if any)
    const answersWithPhotos = answers.reduce((acc: Answer[], curr: Answer) => {
      if (checkIfPhotoType(curr)) {
        acc = [...acc, { ...curr, FilesGallery: photosForAnswers[curr.Question] }];
      } else {
        acc = [...acc, curr];
      }

      return acc;
    }, []);

    // Sort answers
    const sortedAnswers = answersWithPhotos.sort((a, b) => a.sort - b.sort);

    const summary = {
      AccidentForm: null,
      Payload: sortedAnswers,
      Questionnaire: selectedQuestionnaire?.id,
      SerialNo: serialNumber,
      TotalAnswers: answers.length,
      TotalGravity1Negatives: criticalAnswersNumber,
      TotalNegatives: negativeAnswers || 0,
      createdAt: startTime,
    } as Assessment;

    setCompletedQuestionnaire(summary);
  }, [
    answers,
    serialNumber,
    selectedQuestionnaire,
    startTime,
    photosForAnswers,
    criticalAnswersNumber,
  ]);

  useEffect(() => {
    const isAnimationDone = !!sessionStorage.getItem("animationDone");

    let delay = 0;

    if (!isAnimationDone) {
      delay = hasAccessToAdditionalOptions ? 1800 : 2500;
    }

    setTimeOut(() => {
      if (reportSent) {
        if (hasAccessToAdditionalOptions) {
          navigateToEndScreenStepper();
        } else {
          handleBackToHomePage();
        }

        sessionStorage.removeItem("animationDone");
      }
    }, delay);
  }, [
    reportSent,
    hasAccessToAdditionalOptions,
    navigateToEndScreenStepper,
    handleBackToHomePage,
    setTimeOut,
  ]);

  useEffect(() => {
    const delay = gravityAlertVisible || termsAcceptVisible ? 0 : 500;
    let disabled = false;

    // 1. gravity only
    if (gravityAlertVisible && !termsAcceptVisible) {
      disabled = !gravityAlertConfirmed;
    }

    // 2. terms only
    if (!gravityAlertVisible && termsAcceptVisible) {
      disabled = !termsAcceptConfirmed;
    }

    // 3. gravity & terms
    if (gravityAlertVisible && termsAcceptVisible) {
      disabled = !(gravityAlertConfirmed && termsAcceptConfirmed);
    }

    setTimeOut(() => {
      setBtnDisabled(disabled);
    }, delay);
  }, [
    gravityAlertVisible,
    gravityAlertConfirmed,
    termsAcceptVisible,
    termsAcceptConfirmed,
    setTimeOut,
  ]);

  return (
    <ContentContainer>
      <Wrapper>
        <Header smallView={smallView}>
          <Arrow onClick={onArrowBackClick} className='back' isLoading={isLoading}>
            <ChevronIcon className='chevron-icon' />
          </Arrow>
          <SummaryLabel>{summaryLabel}</SummaryLabel>
        </Header>

        {allQuestionsAnswered && (
          <>
            <EntityInfo />
            {!reportSent && <Statistics percentage={percentage} />}
            {gravityAlertVisible && (
              <GravityAlert
                gravityAlertInfo={gravityAlertInfo}
                onChange={onCheckboxChange}
              />
            )}
            {termsAcceptVisible && (
              <TermsAcceptBanner termsAccept={termsAccept} onChange={onCheckboxChange} />
            )}
          </>
        )}

        {!reportSent && isLoading && <UploadProgressBar progress={uploadProgress} />}

        {reportSent && <SuccessAnimated message={t("alert.report-sent")} />}

        {!reportSent && allQuestionsAnswered && (
          <ActionsWrapper>
            <Actions spaceBetween>
              <ButtonContainer double>
                <Button
                  label={t("button.discard")}
                  question
                  onClick={openModal}
                  disabled={isLoading}
                />
              </ButtonContainer>

              <ButtonContainer long>
                <Button
                  onClick={handleSendAssessment}
                  label={t("button.send")}
                  loading={isLoading}
                  disabled={btnDisabled || isLoading}
                />
              </ButtonContainer>
            </Actions>
          </ActionsWrapper>
        )}

        <ReportIncompleteInfo
          onClick={onCompleteBtnClick}
          openModal={openModal}
          visible={!allQuestionsAnswered}
        />
      </Wrapper>

      <ConfirmationModal
        message={t("modal.message")}
        onClick={handleBackToHomePage}
        onClose={closeModal}
        open={open}
        buttonLabel={t("button.discard")}
        double
      />
    </ContentContainer>
  );
}

export default Summary;
