import { CountryCodes } from "types/types";

export const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let tabs = {
    machine: "Machine",
    process: "Process",
    inProgress: "In progress",
  };

  let labels = {
    name: "Name",
    model: "Model",
    make: "Brand",
    serialNumber: "Serial No",
    serviceDate: "Service before",
    hourMeter: "Hour meter",
    status: "Status",
    department: "Department",
  };

  let noDescription = "No description...";

  if (isPolish) {
    tabs = {
      machine: "Maszyna",
      process: "Proces",
      inProgress: "W trakcie",
    };

    labels = {
      name: "Nazwa",
      model: "Model",
      make: "Marka",
      serialNumber: "Nr seryjny",
      serviceDate: "Przegląd do",
      hourMeter: "Licznik godzin",
      status: "Status",
      department: "Oddział",
    };

    noDescription = "Brak opisu...";
  }

  return { tabs, labels, noDescription };
};
