export enum RoleNames {
  ADMINISTRATOR = "Administrator",
  MANAGER = "Manager",
  OPERATOR = "Operator",
  LEADER = "Leader",
  INCOGNITO = "Incognito",
}

export type Role = {
  id: string;
  name: RoleNames;
};
