import styled, { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";

import Modal from "components/templates/Modal";
import Button from "components/atoms/Button";

import { ReactComponent as UpdateIcon } from "assets/icons/update.svg";

import { ModalStyles } from "styles/generalStyles";
import { CountryCodes } from "types/types";

const { Content, Message, Actions } = ModalStyles;

const MessageContent = styled.div<{ updating: boolean }>`
  font-size: 1.8rem;
  font-family: GothamBold;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  .update-icon {
    width: 6rem;

    ${({ updating }) =>
      updating &&
      css`
        ${({ theme }) => theme.rotate};
      `}
  }

  .update-title {
    line-height: 1;
  }
`;

const ActionsContainer = styled.div`
  margin-top: 4rem;

  .defer-button {
    background-color: ${({ theme }) => theme.royalBlue};
    font-size: 1.4rem;
    line-height: 2rem;
  }
`;

type UpdatePopUpProps = {
  open: boolean;
  updating: boolean;
  onUpdateButtonClick: () => void;
  onDeferButtonClick: () => void;
  onClose?: () => void;
};

const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let label = {
    remind: "Remind me later",
  };

  if (isPolish) {
    label = {
      remind: "Przypomnij później",
    };
  }

  return { label };
};

function UpdatePopUp({
  open,
  updating,
  onUpdateButtonClick,
  onDeferButtonClick,
  onClose,
}: UpdatePopUpProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");

  const { label } = getTranslations(language);

  const updateBtnLabel = updating
    ? t("update-popup.button.label.updating")
    : t("update-popup.button.label.update");

  return (
    <Modal onClose={onClose} open={open}>
      <Content>
        <Message>
          <MessageContent updating={updating}>
            <UpdateIcon className='update-icon' />
            <span className='update-title'>{t("update-popup.title")}</span>
          </MessageContent>
        </Message>

        <ActionsContainer>
          <Actions>
            <Button
              disabled={updating}
              onClick={onUpdateButtonClick}
              label={updateBtnLabel}
            />
            <Button
              disabled={updating}
              onClick={onDeferButtonClick}
              label={label.remind}
              customClass='defer-button'
            />
          </Actions>
        </ActionsContainer>
      </Content>
    </Modal>
  );
}

export default UpdatePopUp;
