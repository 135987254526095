import { useTranslation } from "react-i18next";

import { MachineProcessStatus } from "types/types";
import { getMachineProcessStatusTranslation, statusColors } from "utils/utils";

import { InfoLine, Label, Value } from "../../styles/task-related.styles";
import { StatusDot } from "pages/machine-process-info/styles/machine-process-info.styles";

function Status({ status }: { status: MachineProcessStatus }) {
  const {
    i18n: { language },
  } = useTranslation();
  const statusValue = getMachineProcessStatusTranslation(language, status);

  return (
    <InfoLine>
      <Label>Status:</Label>
      <Value flex>
        <StatusDot status={status} statusColors={statusColors} />
        <span>{statusValue ?? "---"}</span>
      </Value>
    </InfoLine>
  );
}

export default Status;
