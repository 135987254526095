import { InfoLine, Label, Value } from "../../styles/task-related.styles";

function Department({
  label,
  departmentName,
}: {
  label: string;
  departmentName: string;
}) {
  return (
    <InfoLine>
      <Label>{label}:</Label>
      <Value>{departmentName}</Value>
    </InfoLine>
  );
}

export default Department;
