import { baseURL } from "axios-instance/axios-instance";
import { getCookie } from "react-use-cookie";

import { DirectusFile } from "types/tasks.types";
import { ACCESS_TOKEN } from "utils/utils";

export enum FileTypes {
  IMAGE = "image",
  DOCUMENT = "application",
}

const { IMAGE, DOCUMENT } = FileTypes;

const getFilesOfType = (filesArray: DirectusFile[], type: FileTypes) =>
  filesArray.filter(({ directus_files_id }) => directus_files_id?.type.includes(type));

const createImgSrc = (id: string | undefined, token: string) =>
  id ? `${baseURL}assets/${id}?access_token=${token}` : "";

function useTaskAttachments(files: DirectusFile[]) {
  const token = getCookie(ACCESS_TOKEN);

  let filteredFiles: DirectusFile[] = []; // remove items with directus_files_id === null
  let photoFiles: DirectusFile[] = [];
  let documentFiles: DirectusFile[] = [];
  let photoImgSources: string[] = [];

  if (files && files.length) {
    filteredFiles = files.filter((item) => !!item.directus_files_id);
  }

  if (filteredFiles.length) {
    photoFiles = getFilesOfType(filteredFiles, IMAGE);
    photoImgSources = photoFiles.map((item) =>
      createImgSrc(item.directus_files_id?.id, token),
    );

    documentFiles = getFilesOfType(filteredFiles, DOCUMENT);
  }

  return {
    filteredFiles,
    photoFiles,
    photoImgSources,
    documentFiles,
  };
}

export default useTaskAttachments;
