import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useQuestionnaire from "hooks/useQuestionnaire";
import useScreen from "hooks/useScreen";
import { isMobile } from "utils/utils";

import { ReactComponent as ChevronIcon } from "assets/icons/navigation-chevron.svg";
import { ReactComponent as ListIcon } from "assets/icons/list-solid.svg";
import { ReactComponent as ListCheckedIcon } from "assets/icons/list-solid-check.svg";
import { ReactComponent as WarningIcon } from "assets/icons/warning-colored.svg";

import {
  Header,
  Arrow,
  ArrowsContainer,
  QuestionNumber,
  ListIconContainer,
} from "../styles/questionnaireStyles";
import { CountryCodes } from "types/types";

type NavHeaderProps = {
  onBackClick: () => void;
  onForwardClick: () => void;
  answered: boolean;
  noQuestions: boolean;
};

const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let title = "Answers preview";

  if (isPolish) {
    title = "Podgląd odpowiedzi";
  }

  return { title };
};

function NavHeader({
  onBackClick,
  onForwardClick,
  answered,
  noQuestions,
}: NavHeaderProps) {
  const [searchParams] = useSearchParams();
  const { currentQuestionIndex, numberOfQuestions } = useQuestionnaire();
  const navigate = useNavigate();
  const { smallView } = useScreen();
  const isListening = !!searchParams.get("listening");
  const { pathname, search } = useLocation();

  const {
    i18n: { language },
  } = useTranslation();
  const { title } = getTranslations(language);

  const handleForwardClick = () => {
    if (noQuestions || isListening) return;
    onForwardClick();
  };

  const handleBackClick = () => {
    if (isListening) return;

    onBackClick();
  };

  const onListIconClick = () => {
    const tableOfContentsReturnPath = pathname + search;
    localStorage.setItem("tableOfContentsReturnPath", tableOfContentsReturnPath);
    navigate("/table-of-contents");
  };

  const getProps = () => ({
    className: "list-icon",
    title: !isListening ? title : "",
    onClick: !isListening ? onListIconClick : () => {},
  });

  return currentQuestionIndex > -1 ? (
    <Header smallView={smallView}>
      {noQuestions ? (
        <div style={{ flexBasis: "22%" }} />
      ) : (
        <ListIconContainer isMobile={isMobile} disabled={isListening}>
          {answered ? <ListCheckedIcon {...getProps()} /> : <ListIcon {...getProps()} />}
        </ListIconContainer>
      )}
      <QuestionNumber>
        {noQuestions ? (
          <WarningIcon className='warning-icon' />
        ) : (
          <span className='value'>
            {currentQuestionIndex + 1}
            <span style={{ fontFamily: "GothamBook" }}>/</span>
            {numberOfQuestions}
          </span>
        )}
      </QuestionNumber>

      <ArrowsContainer>
        <Arrow onClick={handleBackClick} isMobile={isMobile} isBlocked={isListening}>
          <ChevronIcon className='chevron-icon' />
        </Arrow>
        <Arrow
          onClick={handleForwardClick}
          className='forward'
          isMobile={isMobile}
          isBlocked={noQuestions || isListening}
        >
          <ChevronIcon className='chevron-icon' />
        </Arrow>
      </ArrowsContainer>
    </Header>
  ) : null;
}

export default NavHeader;
