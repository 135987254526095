import { Answer } from "types/types";

export const createNegativeAnswersList = (
  negativeAnswers: Answer[],
  questionLabel: string,
) =>
  negativeAnswers
    .map(
      (answer) =>
        `[${questionLabel}: ${answer.sort}] ${answer.OriginalText}${
          answer.ReactionValue ? `\n\n> ${answer.ReactionValue}` : ""
        }`,
    )
    .join("\n\n");

export const getTaskDescription = (
  Title: string,
  negativeAnswersList: string,
  negativeLabel: string,
) =>
  Title +
  `${!!negativeAnswersList ? `\n\n${negativeLabel}:\n\n` : ""}` +
  negativeAnswersList;

export const createFileObjectForPayload = (id: string) => ({
  directus_files_id: { id },
  Tasks_id: "+",
});
