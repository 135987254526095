import { InfoLine, Value } from "../../styles/task-related.styles";

import Linkify from "pages/machine-process-info/components/Linkify";

function Misc({ misc }: { misc: string }) {
  if (!misc) {
    return null;
  }

  return (
    <InfoLine>
      <Value regularFont>
        <Linkify text={misc} />
      </Value>
    </InfoLine>
  );
}

export default Misc;
