import styled from "styled-components/macro";

import { DirectusFile } from "types/tasks.types";

import { isMobile } from "utils/utils";
import useTaskAttachments from "hooks/useTaskAttachments";

import { ReactComponent as DocumentIcon } from "assets/icons/pdf.svg";

import { StyledLabel } from "styles/generalStyles";
import { FilesCount } from "pages/user-tasks/styles/tasks.styles";

const Container = styled.div`
  margin-top: 1rem;
`;

const PreviewContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const FilesPreviewContainer = styled.div<{ isMobile: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: ${({ theme }) => theme.white};
  cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};
  position: relative;

  .icon-document {
    width: 2.2rem;
    height: 2.2rem;
  }
`;

const Wrapper = styled.div`
  width: 4.2rem;
  height: 4.2rem;
  position: relative;
`;

const PhotosPreview = styled.img`
  width: 100%;
`;

type DirectusFileProps = {
  files: DirectusFile[];
  label: string;
  navigateToTaskPhotos: () => void;
  navigateToTaskDocuments: () => void;
};

function Attachments({
  files,
  label,
  navigateToTaskPhotos,
  navigateToTaskDocuments,
}: DirectusFileProps) {
  const { photoImgSources, documentFiles } = useTaskAttachments(files);

  return !!photoImgSources.length || !!documentFiles.length ? (
    <Container>
      <StyledLabel>{label}</StyledLabel>
      <PreviewContainer>
        {!!photoImgSources.length && (
          <Wrapper>
            <FilesPreviewContainer isMobile={isMobile} onClick={navigateToTaskPhotos}>
              <PhotosPreview src={photoImgSources[0]} alt='' />
            </FilesPreviewContainer>
            <FilesCount>{photoImgSources.length}</FilesCount>
          </Wrapper>
        )}

        {!!documentFiles.length && (
          <Wrapper>
            <FilesPreviewContainer isMobile={isMobile} onClick={navigateToTaskDocuments}>
              <DocumentIcon className='icon-document' />
            </FilesPreviewContainer>
            <FilesCount>{documentFiles.length}</FilesCount>
          </Wrapper>
        )}
      </PreviewContainer>
    </Container>
  ) : null;
}

export default Attachments;
