import styled from "styled-components/macro";

export const FileLink = styled.a`
  display: flex;
  align-items: center;
  gap: 1rem;
  text-decoration: none;

  &:link,
  &:active,
  &:visited {
    color: ${({ theme }) => theme.primary};
  }
`;

export const FileName = styled.span`
  height: 4rem;
  max-width: calc(100% - 7.8rem);

  overflow: hidden;
  ${({ theme }) => theme.ellipsisMultiline};
`;
