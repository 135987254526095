import { Stores } from "types/types";
import useIndexedDB from "./useIndexedDB";
import { PhotoGallery, TaskPhotoObjectStoreItem } from "types/tasks.types";

function useTasksPhotosObjectStore() {
  const store: Stores = Stores.PhotosTasks;
  const { addItem, getItem, updateItem, deleteAll } = useIndexedDB(store);

  const setUpTasksPhotosObjectStore = async (taskId: string) => {
    await addItem({ id: taskId, PhotoGallery: null });
  };

  const getTaskPhotosFromIndexedDB = async (taskId: string) => {
    const photoObject = (await getItem(taskId)) as TaskPhotoObjectStoreItem;

    if (photoObject) {
      return photoObject.PhotoGallery;
    }
  };

  const updateTaskPhotosInIndexedDB = (payload: {
    PhotoGallery: PhotoGallery | null;
    id: string;
  }) => {
    updateItem(payload);
  };

  const deleteTaskPhotosInIndexedDB = () => {
    deleteAll();
  };

  return {
    setUpTasksPhotosObjectStore,
    deleteTaskPhotosInIndexedDB,
    getTaskPhotosFromIndexedDB,
    updateTaskPhotosInIndexedDB,
  };
}

export default useTasksPhotosObjectStore;
