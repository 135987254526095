import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import * as Bowser from "bowser";

import useScreen from "hooks/useScreen";
import useDevices from "hooks/useDevices";
import { getBrowserName, getOsName, isAPK, isMobile, isPWA } from "utils/utils";

import { ReactComponent as WindowsIcon } from "assets/icons/system-windows-10.svg";
import { ReactComponent as AndroidIcon } from "assets/icons/system-android-os.svg";
import { ReactComponent as IOsIcon } from "assets/icons/system-ios-logo.svg";

import Button from "components/atoms/Button";

type Device = {
  deviceId: string;
  kind: MediaDeviceKind;
  label: string;
  groupId: string;
};

type SupportedConstraints = {
  aspectRatio: boolean;
  autoGainControl: boolean;
  brightness: boolean;
  channelCount: boolean;
  colorTemperature: boolean;
  contrast: boolean;
  deviceId: boolean;
  displaySurface: boolean;
  echoCancellation: boolean;
  exposureCompensation: boolean;
  exposureMode: boolean;
  exposureTime: boolean;
  facingMode: boolean;
  focusDistance: boolean;
  focusMode: boolean;
  frameRate: boolean;
  groupId: boolean;
  height: boolean;
  iso: boolean;
  latency: boolean;
  noiseSuppression: boolean;
  pan: boolean;
  pointsOfInterest: boolean;
  resizeMode: boolean;
  sampleRate: boolean;
  sampleSize: boolean;
  saturation: boolean;
  sharpness: boolean;
  suppressLocalAudioPlayback: boolean;
  tilt: boolean;
  torch: boolean;
  whiteBalanceMode: boolean;
  width: boolean;
  zoom: boolean;
};

type Kind = "audio" | "video";

const Container = styled.div`
  max-width: 45rem;
  margin: 0 auto;
  color: ${({ theme }) => theme.primary};
  overflow: scroll;
  position: relative;
  ${({ theme }) => theme.fillUpRemainingSpace}
  padding-left: 1rem;
`;

const Line = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;

  .bold {
    font-weight: 700;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .system-icon {
    width: 1.7rem;
    height: 1.7rem;
    margin-left: 0.2rem;
  }

  .windows {
    margin-bottom: 0.1rem;
  }
`;

const Header = styled.h4`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`;

const ButtonContainer = styled.div`
  width: 15rem;
`;

function DeviceInfo() {
  const [devices, setDevices] = useState<Device[]>([]);
  const [phoneModel, setPhoneModel] = useState("");
  const [videoTrack, setVideoTrack] = useState<MediaStreamTrack>();
  const [imageCaptureSupported, setImageCaptureSupported] = useState<
    boolean | undefined
  >();

  const [supportedConstraints, setSupportedConstraints] =
    useState<SupportedConstraints>();
  const { orientation } = useScreen();
  const navigate = useNavigate();

  const operatingSystem = getOsName();
  const browserName = getBrowserName();
  const { isVideoInput } = useDevices();

  const browser = Bowser.getParser(window.navigator.userAgent);

  const getDevices = (deviceKind: Kind) =>
    devices.filter(({ kind }) => kind.includes(deviceKind));

  const audioDevices = getDevices("audio");
  const videoDevices = getDevices("video");

  let stream: MediaStream;
  let systemIcon = null;

  switch (operatingSystem) {
    case "Windows 10":
      systemIcon = <WindowsIcon className='system-icon windows' />;
      break;

    case "Android":
      systemIcon = <AndroidIcon className='system-icon' />;
      break;

    case "iPhone":
      systemIcon = <IOsIcon className='system-icon' />;
      break;
  }

  async function getMedia() {
    try {
      stream = await navigator.mediaDevices.getUserMedia({ video: true });

      stream.getVideoTracks().forEach((track: MediaStreamTrack) => {});
    } catch (err) {
      /* handle the error */
    }
  }

  const stopStream = () => {
    if (stream) {
      stream.getVideoTracks().forEach((track: MediaStreamTrack) => {
        track.stop();
      });
    }
  };

  const getImageCaptureSupport = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: true,
    });
    const [track] = stream.getVideoTracks();

    setVideoTrack(track);

    try {
      const imageCapture = new ImageCapture(track);

      if (imageCapture) {
        setImageCaptureSupported(true);
      } else {
        setImageCaptureSupported(false);
      }
    } catch {
      setImageCaptureSupported(false);
    }
  };

  const getAppType = () => {
    let appType = "Web";

    if (isPWA) {
      appType = "PWA";
    }

    if (isAPK) {
      appType = "APK";
    }

    return appType;
  };

  useEffect(() => {
    (async () => {
      if ("mediaDevices" in navigator) {
        const { mediaDevices } = navigator;

        const devices = await mediaDevices.enumerateDevices();
        const constraints =
          navigator.mediaDevices.getSupportedConstraints() as SupportedConstraints;

        setSupportedConstraints(constraints);
        setDevices(devices);
      }

      try {
        window.navigator.userAgentData
          ?.getHighEntropyValues([
            "architecture",
            "model",
            "platform",
            "platformVersion",
            "fullVersionList",
          ])
          .then((ua: UADataValues) => {
            const model = ua["model"];

            if (model) {
              setPhoneModel(model);
            }
          });
      } catch {}

      // getImageCaptureSupport();
    })();
  }, []);

  return (
    <Container>
      <Line>
        <span className='bold'>System:</span>&nbsp;{browser.getOS().name}&nbsp;
        {browser.getOS().versionName || browser.getOS().version}&nbsp;
        <span>{systemIcon}</span>
      </Line>

      <Line>
        <span className='bold'>Przeglądarka:</span>&nbsp;
        <span className='capitalize'>
          {browser.getBrowserName()}&nbsp;{browser.getBrowserVersion()}
        </span>
      </Line>

      <Line>
        <span className='bold'>Typ aplikacji:</span>&nbsp;
        <span className='capitalize'>{getAppType()}</span>
      </Line>

      <Line>
        <span className='bold'>Urządzenie:</span>&nbsp;
        <span>
          {browser.getPlatform().type}&nbsp;{isMobile && phoneModel}
        </span>
      </Line>

      <div
        onClick={() => {
          if (!isVideoInput) return;
          navigate("/camera-test");
        }}
      >
        <Header>Urządzenia video:</Header>
        <ul>
          {videoDevices.map((device, index) => (
            <li key={`${device.groupId}-${index}`}>
              <div>{device.label || device.kind}</div>
            </li>
          ))}
        </ul>
      </div>

      <Header>Rozdzielczość ekranu:</Header>
      <div>szerokość: {window.screen.width}px</div>
      <div>wysokość: {window.screen.height}px</div>

      <Header>Rozmiar okna aplikacji:</Header>
      <div>szerokość: {window.innerWidth}px</div>
      <div>wysokość: {window.innerHeight}px</div>

      <Line
        onClick={() => {
          navigate("/reload-test");
        }}
      >
        <button
          style={{
            padding: "0.5rem 1rem",
            backgroundColor: "#212f5a",
            // backgroundColor: "limegreen",
            color: "#fff",
            fontFamily: "GothamBook",
            borderRadius: "0.4rem",
            border: "none",
          }}
        >
          reload test
        </button>
        &nbsp;
      </Line>

      {/* <Line>
        <span className='bold'>Storage:</span>&nbsp;
        <span>
          {convertBytes(usedSpace)}&nbsp;/&nbsp;
          {convertBytes(limit)}
          &nbsp; &#x2772;{percentage}%&#x2773;
        </span>
      </Line> */}

      {/* <Line>
        <span className='bold'>Wsparcie ImageCapture:</span>&nbsp;
        <span>
          {imageCaptureSupported === undefined
            ? "---"
            : imageCaptureSupported
            ? "TAK"
            : "NIE"}
        </span>
      </Line> */}

      {/* <Header>Camera API:</Header> */}
      {/* <div>pan: {supportedConstraints?.pan ? "TAK" : "NIE"}</div> */}
      {/* <div>titl: {supportedConstraints?.tilt ? "TAK" : "NIE"}</div> */}
      {/* <div>zoom: {supportedConstraints?.zoom ? "TAK" : "NIE"}</div> */}
      {/* <div>lampa: {supportedConstraints?.torch ? "TAK" : "NIE"}</div> */}

      {/* <Line>
        <span className='bold'>Secure Context:</span>&nbsp;
        {window.isSecureContext ? "TAK" : "NIE"}
      </Line> */}

      {/* <Buttons>
        <button className='btn' onClick={getMedia}>
          STREAM
        </button>
        <button className='btn' onClick={stopStream}>
          STOP STREAM
        </button>
      </Buttons> */}
      <Actions>
        <ButtonContainer>
          <Button
            label='Zamknij'
            onClick={() => {
              // videoTrack?.stop();
              navigate(-1);
            }}
          />
        </ButtonContainer>
      </Actions>
    </Container>
  );
}

export default DeviceInfo;
