import styled, { css } from "styled-components/macro";

export const InfoLine = styled.div<{ vertical?: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #fff;
  border-radius: 0.6rem;
  padding: 0.5rem;

  ${({ vertical }) =>
    vertical &&
    css`
      flex-direction: column;
      align-items: flex-start;
    `}

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`;

export const Label = styled.span`
  font-size: 1.5rem;
`;

export const Value = styled.span<{ regularFont?: boolean; flex?: boolean }>`
  font-family: GothamBold;

  ${({ regularFont }) =>
    regularFont &&
    css`
      font-family: GothamBook;
    `}

  ${({ flex }) =>
    flex &&
    css`
      display: flex;
      align-items: center;
      gap: 0.5rem;
    `}
`;
