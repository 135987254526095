import Modal from "components/templates/Modal";
import Button from "components/atoms/Button";

import { ReactComponent as WarningIcon } from "assets/icons/warning.svg";

import { ModalStyles } from "styles/generalStyles";

const { Content, Message, Actions, ButtonContainer } = ModalStyles;

type ConfirmationModalProps = {
  open: boolean;
  onClose?: () => void;
  onClick: () => void;
  onNoClick?: () => void;
  header?: string | JSX.Element;
  message: string | JSX.Element;
  error?: string;
  loading?: boolean;
  buttonLabel?: string | JSX.Element;
  noButtonLabel?: string;
  buttonMiddle?: boolean;
  warning?: boolean;
  double?: boolean;
  closeDisabled?: boolean;
};

function ConfirmationModal({
  open,
  onClose,
  onClick,
  onNoClick,
  header = "",
  message,
  error = "",
  loading = false,
  buttonLabel = "Usuń",
  noButtonLabel,
  buttonMiddle = false,
  warning = false,
  double = false,
  closeDisabled = false,
}: ConfirmationModalProps) {
  if (warning) {
    header = <WarningIcon className='warning-icon' />;
  }

  return (
    <Modal header={header} onClose={onClose} open={open} closeDisabled={closeDisabled}>
      <Content>
        <Message>{message}</Message>

        <Actions>
          {noButtonLabel && (
            <ButtonContainer middle={buttonMiddle} double={double}>
              <Button onClick={onNoClick} label={noButtonLabel} noButton />
            </ButtonContainer>
          )}

          <ButtonContainer middle={buttonMiddle} double={double}>
            <Button
              disabled={loading || !!error}
              loading={loading}
              onClick={onClick}
              label={buttonLabel}
            />
          </ButtonContainer>
        </Actions>
      </Content>
    </Modal>
  );
}

export default ConfirmationModal;
