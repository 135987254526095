import styled from "styled-components/macro";
import { useSearchParams } from "react-router-dom";

import useServiceWorker from "hooks/useServiceWorker";

import BackArrow from "components/atoms/BackArrow";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  border-radius: 0.6rem;
  padding: 1rem 0;
  background-color: ${({ theme }) => theme.white};
  border: 2px solid;
`;

const Header = styled.header`
  text-align: center;
  font-family: GothamBold;
  position: relative;

  .back-arrow {
    left: -10.5rem;
    top: -0.6rem;
    width: 2.5rem;
  }
`;

const Status = styled.div`
  font-size: 1.8rem;
  text-transform: uppercase;
`;

function ServiceWorkerStatusPreview() {
  const { swRegistration } = useServiceWorker();
  const [, setSearchParams] = useSearchParams();

  const onBackArrowClick = () => {
    setSearchParams({});
  };

  let content = null;

  if (swRegistration) {
    let value = "";

    if (!!swRegistration.active) {
      value = "Active";
    }

    if (!!swRegistration.installing) {
      value = "Installing";
    }

    if (!!swRegistration.waiting) {
      value = "Waiting";
    }

    content = <Status>{value}</Status>;
  }

  return (
    <Container>
      <Header>
        <BackArrow customClass='back-arrow' onClick={onBackArrowClick} />
        <span>Service Worker Status</span>
      </Header>
      {content}
    </Container>
  );
}

export default ServiceWorkerStatusPreview;
