import { differenceInDays } from "date-fns";

const UPDATE_DEFERRED = "updateDeferred";

const getUpdateDeferredFromStorage = () => {
  const localData = localStorage.getItem(UPDATE_DEFERRED);

  return localData ? JSON.parse(localData) : null;
};

const checkIfShouldDisplayPopUp = (currentDate: Date) => {
  const updateDeferredOn = getUpdateDeferredFromStorage();

  return !!updateDeferredOn
    ? differenceInDays(currentDate, new Date(updateDeferredOn)) > 6
    : false;
};

function useUpdateDefer() {
  const currentDate = new Date();
  const isUpdateDeferred = !!getUpdateDeferredFromStorage();
  const shouldDisplayPopUp = checkIfShouldDisplayPopUp(currentDate);

  const setReminderInStorage = () => {
    localStorage.setItem(UPDATE_DEFERRED, JSON.stringify(currentDate));
  };

  const removeReminderFromStorage = () => {
    if (!getUpdateDeferredFromStorage()) return;
    localStorage.removeItem(UPDATE_DEFERRED);
  };

  return {
    setReminderInStorage,
    removeReminderFromStorage,
    isUpdateDeferred,
    shouldDisplayPopUp,
  };
}

export default useUpdateDefer;
