import { CountryCodes } from "types/types";

export const getTopBarTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  //   ----- ENGLISH -----

  let alert = {
    offline: "You're offline",
    update: "Update application",
    updating: "Updating...",
  };

  let modalMessage = {
    signout: "Are you sure you want to sign out?",
  };

  let button = {
    label: {
      signout: "Sign out",
    },
  };

  //   ----- POLISH -----

  if (isPolish) {
    alert = {
      offline: "Jesteś offline",
      update: "Aktualizuj aplikację",
      updating: "Aktualizuję...",
    };

    modalMessage = {
      signout: "Czy na pewno chcesz się wylogować?",
    };

    button = {
      label: {
        signout: "Wyloguj",
      },
    };
  }

  return { alert, modalMessage, button };
};
