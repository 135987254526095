import { ChangeEvent } from "react";
import styled, { css } from "styled-components/macro";

import { AlertNames } from "../types/summary.types";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.7rem;
`;

const Checkbox = styled.input`
  width: 2.5rem;
  height: 2.5rem;
  border-width: 0.4rem;

  &:hover {
    cursor: pointer;
  }
`;

const Label = styled.label<{ isBold: boolean }>`
  font-size: 1.8rem;
  opacity: 0.9;
  user-select: none;

  ${({ isBold }) =>
    isBold &&
    css`
      font-family: GothamBold;
    `}

  &:hover {
    cursor: pointer;
  }
`;

type ConfirmationCheckboxProps = {
  label: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  name: AlertNames;
};

const { GRAVITY } = AlertNames;

function ConfirmationCheckbox({ label, onChange, name }: ConfirmationCheckboxProps) {
  return (
    <Container>
      <Checkbox type='checkbox' id={name} onChange={onChange} name={name} />
      <Label htmlFor={name} isBold={name === GRAVITY}>
        {label}
      </Label>
    </Container>
  );
}

export default ConfirmationCheckbox;
