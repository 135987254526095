import styled, { css } from "styled-components/macro";

export const Header = styled.div<{ isMobile: boolean }>`
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 0.2rem;
  font-family: AuraAspect;
  text-align: center;
  position: relative;

  @media screen and (min-height: 616px) {
    margin-top: 2rem;
  }

  .header-title {
    display: inline-block;
    max-width: 82%;
    font-size: 2rem;

    @media screen and (min-width: 360px) {
      font-size: 2.2rem;
    }

    @media screen and (min-width: 380px) {
      font-size: 2.4rem;
    }

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .move-arrow {
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const Placeholder = styled.span`
  opacity: 0.5;
  font-style: italic;
  font-size: 1.6rem;
  margin-top: 0.2rem;
`;

export const DeadlineInputWrapper = styled.div<{ isValue: boolean }>`
  .deadline-input {
    ${({ isValue }) =>
      !isValue &&
      css`
        color: hsl(0, 0%, 50%);
      `}
  }
`;
