import { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";

import { isMobile } from "utils/utils";
import useTasksPhotosObjectStore from "hooks/useTasksPhotosObjectStore";
import useDevices from "hooks/useDevices";

import { ReactComponent as PhotoIcon } from "assets/icons/add-photo.svg";
import { ReactComponent as FileIcon } from "assets/icons/add-file.svg";
import { ReactComponent as DocumentIcon } from "assets/icons/pdf.svg";

import { FilesCount } from "pages/user-tasks/styles/tasks.styles";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
`;

const IconContainer = styled.div<{ isMobile: boolean }>`
  background-color: ${({ theme }) => theme.white};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};
  width: 4.2rem;
  height: 4.2rem;
  position: relative;

  .icon {
    &-photo {
      width: 2.2rem;
      height: 2.2rem;
      opacity: 0.5;
    }
  }

  .icon {
    &-file {
      width: 2rem;
      height: 2rem;
      opacity: 0.4;
      transform: rotate(180deg) scaleX(-1) translate(0.1rem, 0.1rem);
    }
  }

  .icon {
    &-document {
      width: 2.2rem;
      height: 2.2rem;
    }
  }
`;

const PhotoPreviewContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
  display: grid;
  place-content: center;
`;
const PhotoPreview = styled.img`
  width: 100%;
`;

const getNumberOfDocuments = () => {
  const localData = sessionStorage.getItem("documentsToBeUploaded");

  return localData ? [...JSON.parse(localData)].length : 0;
};

type AttachmentIconsProps = {
  taskId: string;
  tooltip: { photo: string; document: string };
};

function AttachmentIcons({ taskId, tooltip }: AttachmentIconsProps) {
  const [photoPreview, setPhotoPreview] = useState({ src: "", photoCount: 0 });
  const numberOfDocuments = getNumberOfDocuments();
  const { getTaskPhotosFromIndexedDB } = useTasksPhotosObjectStore();
  const { isVideoInput } = useDevices();
  const navigate = useNavigate();
  const srcRef = useRef("");

  useEffect(() => {
    if (taskId) {
      getTaskPhotosFromIndexedDB(taskId).then((photosFromDB) => {
        if (photosFromDB) {
          const firstPhoto = photosFromDB[0];
          const { current: src } = srcRef;

          if (firstPhoto) {
            srcRef.current = URL.createObjectURL(photosFromDB[0].file);
            setPhotoPreview({ src, photoCount: photosFromDB.length });
          }
        }
      });
    }
  }, [taskId]);

  return (
    <Container>
      {isVideoInput && (
        <IconContainer
          isMobile={isMobile}
          onClick={() => {
            navigate(`/task-creator/photos/?taskId=${taskId}`, { replace: true });
          }}
          title={tooltip.photo}
        >
          {!!photoPreview.src ? (
            <PhotoPreviewContainer>
              <PhotoPreview
                src={photoPreview.src}
                alt=''
                onLoad={() => {
                  URL.revokeObjectURL(srcRef.current);
                }}
              />
            </PhotoPreviewContainer>
          ) : (
            <PhotoIcon className='icon-photo' />
          )}
          {!!photoPreview.photoCount && (
            <FilesCount>{photoPreview.photoCount}</FilesCount>
          )}
        </IconContainer>
      )}
      <IconContainer
        isMobile={isMobile}
        title={tooltip.document}
        onClick={() => {
          navigate("/task-creator/documents", { replace: true });
        }}
      >
        {!!numberOfDocuments ? (
          <DocumentIcon className='icon-document' />
        ) : (
          <FileIcon className='icon-file' />
        )}

        {!!numberOfDocuments && <FilesCount>{numberOfDocuments}</FilesCount>}
      </IconContainer>
    </Container>
  );
}

export default AttachmentIcons;
