import { useEffect } from "react";
import { useQuery } from "react-query";
import { getCookie } from "react-use-cookie";

import { ACCESS_TOKEN } from "utils/utils";
import { CreatedTask } from "types/tasks.types";
import { getRelatedItems } from "api/tasks";

function useRelatedFiles(task: CreatedTask) {
  const token = getCookie(ACCESS_TOKEN);

  const { data } = useQuery("tasks", getRelatedItems(token, task.id), {
    keepPreviousData: false,
  });

  const relatedList = data ? data : [];

  useEffect(() => {
    if (data) {
      sessionStorage.setItem("taskRelated", JSON.stringify(data));
    }
  }, [data]);

  return { relatedList };
}

export default useRelatedFiles;
